import { useEffect, useState } from 'react';
import { Link, useOutletContext, useParams } from 'react-router-dom';
import * as DataUtil from '../../util/data';
// import Input from "../form/Input";
// import TextArea from "../form/TextArea";
// import * as DisplayUtil from '../../util/display';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const IdeaSourceView = () => {

    // Get ID from the URL.
    let { id } = useParams();

    // Define constants. Edit this.
    const editRoute = "idea-source-edit";
    const getData = (j, i) => DataUtil.GetIdeaSource(j, i);

    // Define stateful variables.
    let { jwt } = useOutletContext();
    const [objData, setObjData] = useState({});

    // useEffect Hook
    useEffect(() => {

        if (!jwt || jwt === "") {
            return;
        }

        if (id < 1) {
            return;
        }

        getData(jwt, id).then(d => setObjData(d));
        
    }, [id, jwt])

    // Return for no data
    if (!jwt || !objData) {
        return <>Nothing here!</>
    }

    // *****************************
    // Main return
    return (
        <div className="row">
            <div className="col-md-12 pb-3" style={{height: "90vh", overflow: "scroll"}}>

                {/* Heading */}
                <div className="float-end mt-3">
                    <Link to={`/${editRoute}/${objData.id}`} className="link-secondary">
                        <FontAwesomeIcon icon="fa-solid fa-pen-to-square" size="2x" />
                    </Link>
                </div>
                <h1 className="display-5">
                    <strong>{objData?.name}</strong>
                </h1>

                {/* Summary */}
                <p className="lead">{objData?.summary}</p>
                <hr />
            </div>
        </div>
    )
}

export default IdeaSourceView;