import { Link, Outlet, useNavigate } from 'react-router-dom';
import atom from './images/atom.svg'
import microscope from './images/microscope.svg'
import testTubes from './images/test-tubes.svg'
import computer from './images/computer.svg'
import { useCallback, useEffect, useState } from 'react';
import Alert from './components/elements/Alert';

import { library } from '@fortawesome/fontawesome-svg-core'
import * as Icons from '@fortawesome/free-solid-svg-icons'
import * as RegularIcons from '@fortawesome/free-regular-svg-icons'

const iconList = Object
  .keys(Icons)
  .filter(key => key !== "fas" && key !== "prefix" )
  .map(icon => Icons[icon])
const iconListRegular = Object
  .keys(RegularIcons)
  .filter(key => key !== "far" && key !== "prefix" )
  .map(icon => RegularIcons[icon])
library.add(...iconList)
library.add(...iconListRegular)

function App() {

  const [jwt, setJwt] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertClassName, setAlertClassName] = useState("d-none");

  const [tickInterval, setTickInterval] = useState();

  const navigate = useNavigate();

  const logOut = () => {

    const requestOptions = {
      method: "GET",
      credentials: "include",
    };

    fetch(`${process.env.REACT_APP_BACKEND}/logout`, requestOptions)
      .catch(error => {
        console.log("Error logging out.\n", error)
      })
      .finally(() => {
        setJwt("");
        toggleRefresh(false);
      });

    navigate("/login");
  }

  const toggleRefresh = useCallback((status) => {
    // console.log("clicked");

    if (status) {
      // console.log("turning on ticking");
      let i = setInterval(() => {
        console.log("Refreshing access token...");
        const requestOptions = {
          method: "GET",
          credentials: "include",
        }

        fetch(`${process.env.REACT_APP_BACKEND}/refresh`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.access_token) {
            setJwt(data.access_token);
          }
        })
        .catch(error => {
          console.log("User is not logged in.\n")
          navigate("/login");
        });
      }, (60000 * 59));
      setTickInterval(i);
      // console.log("setting tick interval to", i);
    } else {
      // console.log("turning off ticking");
      // console.log("turning off tickInterval", tickInterval);
      clearInterval(tickInterval);
      setTickInterval(null);
    }
  }, [tickInterval, navigate])

  useEffect(() => {

    if (jwt === "") {
      const requestOptions = {
        method: "GET",
        credentials: "include",
      }

      fetch(`${process.env.REACT_APP_BACKEND}/refresh`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.access_token) {
            setJwt(data.access_token);
            toggleRefresh(true);
          }
        })
        .catch(error => {
          console.log("User is not logged in\n", error)
        });
    }
  }, [jwt, toggleRefresh])

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <h1 className="mt-3">
            <img src={atom} alt="atom" height="42px" />
            theLab
          </h1>
        </div>
        <div className="col text-end">
          {jwt === ""
            ? <Link to="/login"><span className="badge bg-success">Log In</span></Link>
            : <a href="#!" onClick={logOut}><span className="badge bg-danger">Log Out</span></a>
          }
        </div>
        <hr className="mb-3"></hr>
      </div>

      <div className="row">
        <div className="col-md-2">
          <nav>
            <div className="list-group">
              <Link to="/" className="list-group-item list-group-item-action">
                <img src={atom} alt="atom" height="24px" /> Home
              </Link>
              {jwt !== "" &&
                <>
                  <Link to="/research" className="list-group-item list-group-item-action">
                    <img src={microscope} alt="microscope" height="24px" /> Research
                  </Link>
                  <Link to="/experiment" className="list-group-item list-group-item-action">
                    <img src={testTubes} alt="test tubes" height="24px" /> Experiment
                  </Link>
                  <Link to="/invent" className="list-group-item list-group-item-action">
                    <img src={computer} alt="computer" height="24px" /> Invent
                  </Link>
                </>
              }
            </div>
          </nav>
        </div>
        <div className="col-md-10">
          <Alert 
            message={alertMessage}
            className={alertClassName}
          />
          <Outlet context={{
            jwt, setJwt, setAlertMessage, setAlertClassName, toggleRefresh
          }}/>
        </div>
      </div>
    </div>
  );
}

export default App;