import { useEffect, useState } from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { Link, useOutletContext, useParams } from 'react-router-dom';
import books from './../../images/books.svg'
import * as DataUtil from './../../util/data';
import Input from "../form/Input";
import TextArea from "../form/TextArea";
import MarkdownEditor from "../form/MarkdownEditor";
import * as DisplayUtil from './../../util/display';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import MDEditor from '@uiw/react-md-editor';

const ResearchAreaView = () => {

    let { jwt } = useOutletContext();
    let { id } = useParams();

    const [researchArea, setResearchArea] = useState({});
    const [paradigms, setParadigms] = useState([]);
    const [ideaSources, setIdeaSources] = useState([]);
    const [infoSources, setInfoSources] = useState([]);
    const [painPoints, setPainPoints] = useState([]);
    
    const [editingInfoSource, setEditingInfoSource] = useState(-1);
    const [editingInfoSourceVal, setEditingInfoSourceVal] = useState("");
    const [editingPainPoint, setEditingPainPoint] = useState(-1);
    const [editingPainPointSummary, setEditingPainPointSummary] = useState("");
    const [editingPainPointDescription, setEditingPainPointDescription] = useState("");

    const [editingNotes, setEditingNotes] = useState(false);
    const [editedNotes, setEditedNotes] = useState("");
    const [notesHeadingHovered, setNotesHeadingHovered] = useState(false);

    // Select an info source for edit
    const selectInfoSourceForEdit = (id, startVal) => {
        setEditingInfoSource(id);
        setEditingInfoSourceVal(startVal);
    }

    // Select a pain point for edit
    const selectPainPointForEdit = (id, startSummary, startDesc) => {
        setEditingPainPoint(id);
        setEditingPainPointSummary(startSummary);
        setEditingPainPointDescription(startDesc);
    }

    // Cancel editing an info source
    const cancelInfoSourceEdit = () => {
        setEditingInfoSource(-1);
        setEditingInfoSourceVal("");
    }

    // Cancel editing a pain point
    const cancelPainPointEdit = () => {
        setEditingPainPoint(-1);
        setEditingPainPointSummary("");
        setEditingPainPointDescription("");
    }

    // Change an edited info source's value
    const handleInfoSourceEditChange = (evt) => {
        setEditingInfoSourceVal(evt.target.value);
    }

    // Change an edited pain point's summary
    const handlePainPointSummaryChange = (evt) => {
        setEditingPainPointSummary(evt.target.value);
    }

    // Change an edited pain point's description
    const handlePainPointDescriptionChange = (evt) => {
        setEditingPainPointDescription(evt.target.value);
    }

    // Save changes to an info source
    const saveInfoSource = (source) => {
        source.summary = editingInfoSourceVal;

        if (source.id > 0) {
            DataUtil.UpdateInfoSource(jwt, source);
        } else if (source.id === 0) {
            DataUtil.InsertInfoSource(jwt, source)
                .then(() => DataUtil.GetAllInfoSourcesByResearchArea(jwt, researchArea.id).then(d => setInfoSources(d)));
        }
        cancelInfoSourceEdit();
    }

    // Save changes to a pain point
    const savePainPoint = (data) => {
        data.summary = editingPainPointSummary;
        data.description = editingPainPointDescription

        if (data.id > 0) {
            DataUtil.UpdatePainPoint(jwt, data);
        } else if (data.id === 0) {
            DataUtil.InsertPainPoint(jwt, data)
                .then(() => DataUtil.GetAllPainPointsByResearchArea(jwt, researchArea.id).then(d => setPainPoints(d)));
        }
        cancelPainPointEdit();
    }

    // Edit notes
    const editNotes = () => {
        setEditedNotes(researchArea.notes);
        setEditingNotes(true);
    }

    // Cancel editing notes
    const cancelEditingNotes = () => {
        setEditedNotes("");
        setEditingNotes(false);
    }

    // Save changes to notes
    const saveResearchArea = () => {
        setResearchArea({
            ...researchArea,
            notes: editedNotes
        })
        DataUtil.UpdateResearchArea(jwt, {...researchArea, notes: editedNotes});
        cancelEditingNotes();
    }

    // Handle deletion of an info source.
    const handleInfoSourceDelete = (id) => {
        DisplayUtil.DisplayDeleteConfirmation(
            jwt, 
            id, 
            "Information Source", 
            DataUtil.DeleteInfoSource, 
            () => {
                DataUtil.GetAllInfoSourcesByResearchArea(jwt, researchArea.id).then(d => setInfoSources(d));
                cancelInfoSourceEdit();
            }
        );
    }

    // Handle deletion of a pain point.
    const handlePainPointDelete = (id) => {
        DisplayUtil.DisplayDeleteConfirmation(
            jwt, 
            id, 
            "Pain Point", 
            DataUtil.DeletePainPoint, 
            () => {
                DataUtil.GetAllPainPointsByResearchArea(jwt, researchArea.id).then(d => setPainPoints(d));
                cancelPainPointEdit();
            }
        );
    }

    // Get JSX for an editable info source.
    const displayInfoSourceEdit = (source) => (
        <li key={source.id} className="list-group-item d-flex justify-content-between align-items-start">
            <div className="d-flex flex-row w-100">
                <div className="flex-grow-1 justify-content-start">
                    <Input 
                        title={"Summary"}
                        type={"text"}
                        name={"Info Source Summary"}
                        className={"form-control"}
                        value={editingInfoSourceVal}
                        onChange={(evt) => handleInfoSourceEditChange(evt)}
                        hideLabel={true}
                        containerClassName={"mr-0"}
                        style={{}}
                        maxLength={512}
                    />
                </div>
                <button type="button" className="btn btn-secondary justify-content-end"
                    onClick={cancelInfoSourceEdit} style={{marginLeft: "10px"}}>
                    <FontAwesomeIcon icon="fa-solid fa-xmark" />
                </button>
                <button type="button" className="btn btn-primary justify-content-end" 
                    onClick={() => saveInfoSource(source)} style={{marginLeft: "10px"}}>
                    <FontAwesomeIcon icon="fa-solid fa-check" />
                </button>
                {source.id !== 0 &&
                <button type="button" className="btn btn-danger justify-content-end" 
                    onClick={() => handleInfoSourceDelete(source.id)} style={{marginLeft: "10px"}}>
                    <FontAwesomeIcon icon="fa-solid fa-trash" />
                </button>
                }
            </div>
        </li>
    );

    // Get JSX for an editable pain point.
    const displayPainPointEdit = (source) => (
        <li key={source.id} className="list-group-item d-flex justify-content-between align-items-start">
            <div className="d-flex flex-row w-100">
                <div className="flex-grow-1 justify-content-start">
                    <Input 
                        title={"Summary"}
                        type={"text"}
                        name={"Pain Point Summary"}
                        className={"form-control"}
                        value={editingPainPointSummary}
                        onChange={(evt) => handlePainPointSummaryChange(evt)}
                        containerClassName={"mr-0"}
                        style={{}}
                        maxLength={512}
                    />
                    <TextArea 
                        title={"Description"}
                        type={"text"}
                        name={"Pain Point Description"}
                        className={"form-control"}
                        value={editingPainPointDescription}
                        onChange={(evt) => handlePainPointDescriptionChange(evt)}
                        containerClassName={"mr-0"}
                        style={{}}
                        maxLength={512}
                    />
                    <div className="d-flex flex-row justify-content-end w-100 mt-3">
                        <button type="button" className="btn btn-secondary justify-content-end"
                            onClick={cancelPainPointEdit} style={{marginLeft: "10px"}}>
                            <FontAwesomeIcon icon="fa-solid fa-xmark" />
                        </button>
                        <button type="button" className="btn btn-primary justify-content-end" 
                            onClick={() => savePainPoint(source)} style={{marginLeft: "10px"}}>
                            <FontAwesomeIcon icon="fa-solid fa-check" />
                        </button>
                        {source.id !== 0 &&
                        <button type="button" className="btn btn-danger justify-content-end" 
                            onClick={() => handlePainPointDelete(source.id)} style={{marginLeft: "10px"}}>
                            <FontAwesomeIcon icon="fa-solid fa-trash" />
                        </button>
                        }
                    </div>
                </div>
            </div>
        </li>
    );

    // An empty info source used for adding new ones.
    const emptyInfoSource = {
        id: 0,
        summary: "",
        research_area_id: researchArea.id,
    };

    // An empty pain point used for adding new ones.
    const emptyPainPoint = {
        id: 0,
        summary: "",
        description: "",
        research_area_id: researchArea.id,
    }

    // useEffect Hook
    useEffect(() => {

        if (!jwt || jwt === "") {
            return;
        }

        if (id < 1) {
            return;
        }

        DataUtil.GetAllSolutionParadigms(jwt).then(d => setParadigms(d));
        DataUtil.GetAllIdeaSources(jwt).then(d => setIdeaSources(d));
        DataUtil.GetAllInfoSourcesByResearchArea(jwt, id).then(d => setInfoSources(d));
        DataUtil.GetAllPainPointsByResearchArea(jwt, id).then(d => setPainPoints(d));
        DataUtil.GetResearchArea(jwt, id).then(d => setResearchArea(d));
        
    }, [id, jwt])

    // Return for no data
    if (!jwt || !researchArea) {
        return <>Nothing here!</>
    }

    // *****************************
    // Main return
    return (
        <div className="row">
            <div className="col-md-9 pb-3" style={{height: "90vh", overflow: "scroll"}}>

                {/* Heading */}
                <div className="float-end mt-3">
                    <Link to={`/research-area-edit/${researchArea.id}`} className="link-secondary">
                        <FontAwesomeIcon icon="fa-solid fa-pen-to-square" size="2x" />
                    </Link>
                </div>
                <h4 className="display-5" style={{marginBottom: "-28px", }}>
                    <img src={books} alt="books" height="42px" width="42px" style={{marginBottom: "10px"}} />
                    Researching
                </h4>
                <h1 className="display-3">
                    <strong>{researchArea?.name}</strong>
                </h1>

                {/* Category Tags */}
                <p>
                    {researchArea?.categories && researchArea.categories.map((c) => (
                        <span key={c.name} className="badge bg-info me-2 rounded-pill text-muted">{c.name}</span>
                    ))}
                </p>
                <p className="lead">{researchArea?.description}</p>
                <hr />

                {/* Information Sources */}
                <h3 className="display-6">Information Sources:</h3>
                <ol className="list-group">

                    {/* All existing info sources */}
                    {infoSources && infoSources.map(source => {

                        let isEditing = editingInfoSource === source.id;

                        if (isEditing) {

                            // Editing an info source
                            return displayInfoSourceEdit(source);
                            
                        } else {
                            
                            // Viewing an info source.
                            let content = source.summary;
                            if (source?.summary?.includes("http:") || source?.summary?.includes("https:")) {
                                content = (
                                    <a href={source.summary} className="link-secondary" target="_blank" rel="noreferrer" 
                                        onClick={(evt) => evt.stopPropagation()}>
                                        {source.summary}
                                        </a>
                                );
                            }
                            return (
                                <li key={source.id} 
                                    className="list-group-item d-flex justify-content-between align-items-start"
                                    onClick={() => selectInfoSourceForEdit(source.id, source.summary)}>
                                    <div className="me-auto">
                                        {content}
                                    </div>
                                </li>
                            );
                        }
                    })}

                    {/* New info source */}
                    {editingInfoSource === 0 &&
                        displayInfoSourceEdit(emptyInfoSource)
                    }
                    {editingInfoSource !== 0 && (
                    <a href="!#" onClick={(evt) => { 
                        evt.preventDefault();
                        selectInfoSourceForEdit(0, "");
                     }} 
                        className="list-group-item list-group-item-success">
                        {(!infoSources || infoSources.length <= 0) && 
                            <span>There's nothing here yet!&nbsp;</span>
                        }
                        Add a new one!
                    </a>
                    )}
                </ol>
                <hr />

                {/* Pain Points */}
                <h3 className="display-6">Pain Points:</h3>
                <ol className="list-group">

                    {/* All existing pain points */}
                    {painPoints && painPoints.map(p => {
                        
                        let isEditing = editingPainPoint === p.id;

                        if (isEditing) {

                            // Editing a pain point
                            return displayPainPointEdit(p);
                            
                        } else {

                            // Viewing a pain point
                            return (
                                <li key={p.id} 
                                    className="list-group-item d-flex justify-content-between align-items-start"
                                    onClick={() => selectPainPointForEdit(p.id, p.summary, p.description)}>
                                    <div className="me-auto">
                                        <div className="fw-bold">{p.summary}</div>
                                        {p.description}
                                    </div>
                                    <div className="text-center">
                                        <Link to={`/experiment-edit/0/pain-point/${p.id}`} >
                                            <FontAwesomeIcon icon="fa-solid fa-flask-vial" className="link-success" alt="text here" />
                                        </Link>
                                    </div>
                                </li>
                            );
                        }
                    })}

                    {/* New pain point */}
                    {editingPainPoint === 0 &&
                        displayPainPointEdit(emptyPainPoint)
                    }
                    {editingPainPoint !== 0 && (
                    <a href="!#" onClick={(evt) => { 
                        evt.preventDefault();
                        selectPainPointForEdit(0, "");
                     }} 
                        className="list-group-item list-group-item-success">
                        {(!painPoints || painPoints.length <= 0) && 
                            <span>There's nothing here yet!&nbsp;</span>
                        }
                        Add a new one!
                    </a>
                    )}
                </ol>
                <hr />
                
                {/* Notes */}
                <h3 className="display-6" 
                    onMouseEnter={() => setNotesHeadingHovered(true)} 
                    onMouseLeave={() => setNotesHeadingHovered(false)}>
                    Notes&nbsp;
                    {notesHeadingHovered && ! editingNotes &&
                        <button type="button" className="btn btn-warning" style={{padding: "0px 5px"}}
                            onClick={() => editNotes()}>
                            <FontAwesomeIcon icon="fa-solid fa-pen" size="xs" />
                        </button>
                    }
                </h3>
                {!editingNotes &&
                    <div className="card p-3 pb-0" onClick={(evt) => {if (evt.detail > 2) {editNotes();}}}>
                        {researchArea?.notes !== "" &&
                            <ReactMarkdown children={researchArea?.notes}/>
                        }
                        {researchArea?.notes === "" &&
                            <div className="pb-3" style={{color: "#999", fontStyle: "italic"}}>No notes on this research area yet.</div>
                        }
                    </div>
                }
                {editingNotes &&
                <>
                    <MarkdownEditor
                        title={"Notes"}
                        className={"form-control"}
                        name={"notes"}
                        value={editedNotes}
                        onChange={(newVal) => { setEditedNotes(newVal) }}
                        preview="edit"
                        hideLabel={true}
                    />
                    <div className="float-end mt-3">
                        <button type="button" className="btn btn-secondary justify-content-end"
                            onClick={() => cancelEditingNotes()} style={{marginLeft: "10px"}}>
                            <FontAwesomeIcon icon="fa-solid fa-xmark" />
                        </button>
                        <button type="button" className="btn btn-primary justify-content-end" 
                            onClick={() => saveResearchArea(researchArea)} style={{marginLeft: "10px"}}>
                            <FontAwesomeIcon icon="fa-solid fa-check" />
                        </button>
                    </div>
                </>
                }
            </div>
            
            {/* Solution Paradigms & Info Sources */}
            <div className="col-3 p-0" style={{height: "90vh"}}>
                <div className="pt-2 card p-2" style={{height: "45vh", overflow: "scroll"}}>
                    <h5 className="card-title">Idea Sources</h5>
                    <ul className="list-group">
                    {ideaSources && ideaSources.map(is => (
                        <li className="list-group-item" key={is.id}>
                            <Link to={`/idea-source/${is.id}`} className="link-secondary" style={{textDecoration: "none"}}>
                                {is.name}
                            </Link>
                        </li>
                    ))}
                    </ul>
                </div>
                <div className="pt-2 card p-2" style={{height: "45vh", overflow: "scroll"}}>
                    <h5 className="card-title">Solution Paradigms</h5>
                    <ul className="list-group">
                    {paradigms && paradigms.map(p => (
                        <li className="list-group-item" key={p.id}>
                            <Link to={`/solution-paradigm/${p.id}`} className="link-secondary" style={{textDecoration: "none"}}>
                                {p.name}
                            </Link>
                        </li>
                    ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default ResearchAreaView;