import { useState } from 'react';
import puzzle from './../../images/puzzle.svg'
import { useEffect } from 'react';
import Input from './../form/Input';
import { Link } from 'react-router-dom';

const GraphQL = () => {

    // Set up stateful variables.
    const [researchAreas, setResearchAreas] = useState([]);
    const [fullList, setFullList] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");

    // Perform a search.
    const performSearch = (searchStr) => {

        console.log("Performing search... (" + searchStr + ")");
        const payload = `
            {
                search(nameContains: "${searchStr}") {
                    id
                    name
                    description
                    created_at
                }
            }`;

        const headers = new Headers();
        headers.append("Content-Type", "application/graphql");
        const requestOptions = {
            method: "POST",
            headers: headers,
            body: payload,
        };

        fetch(`${process.env.REACT_APP_BACKEND}/graph`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                let list = Object.values(data.data.search);
                setResearchAreas(list);
            })
            .catch(err => console.log(err));
    };

    const handleChange = (evt) => {
        console.log(evt.target.value);
        evt.preventDefault();
        let value = evt.target.value;
        setSearchTerm(value)

        if (value.length > 2) {
            performSearch(value);
        } else {
            setResearchAreas(fullList);
        }
    }

    // useEffect.
    useEffect(() => {
        const payload = `
            {
                list {
                    id
                    name
                    description
                    created_at
                }
            }`;
            
        const headers = new Headers();
        headers.append("Content-Type", "application/graphql");
        const requestOptions = {
            method: "POST",
            headers: headers,
            body: payload,
        };

        fetch(`${process.env.REACT_APP_BACKEND}/graph`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                let list = Object.values(data.data.list);
                setResearchAreas(list);
                setFullList(list);
            })
            .catch(err => console.log(err));
    }, [])

    return (
        <div className="">
            <h1>
                <img src={puzzle} alt="puzzle" height="48px" />
                <em>GraphQL</em>
            </h1>
            <hr />

            <form onSubmit={handleChange}>
                <Input
                    title={"Search"}
                    type={"search"}
                    name={"search"}
                    className={"form-control"}
                    value={searchTerm}
                    onChange={handleChange}
                />
            </form>

            {researchAreas ? (
                <table className="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th>Name</th>
                            {/* <th>Created At</th> */}
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        {researchAreas.map(ra => (
                            <tr key={ra.id}>
                                <td>
                                    <Link to={`/research-area${ra.id}`}>
                                        {ra.name}
                                    </Link>
                                </td>
                                {/* <td>{new Date(ra.created_at).toLocaleDateString()}</td> */}
                                <td>{ra.description}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>No research areas (yet!)</p>
            )}
            
        </div>
    )
}

export default GraphQL;