import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState, useCallback } from 'react';
import microscope from './../../images/microscope.svg'
import puzzle from './../../images/puzzle.svg'
import telescope from './../../images/telescope.svg'
import protractor from './../../images/protractor.svg'
import book from './../../images/book.svg'
import books from './../../images/books.svg'
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import * as DataUtil from './../../util/data';
import * as DisplayUtil from './../../util/display';
import Filter from './../form/Filter';
import SearchFilter from '../form/SearchFilter';

const Research = () => {

    const [researchAreas, setResearchAreas] = useState([]);
    const [catFilteredResearchAreas, setCatFilteredResearchAreas] = useState([]);
    const [filteredResearchAreas, setFilteredResearchAreas] = useState([]);
    const [paradigms, setParadigms] = useState([]);
    const [ideaSources, setIdeaSources] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [researchCategories, setResearchCategories] = useState([]);
    const [currCatFilter, setCurrCatFilter] = useState(-1);
    const [currSearchFilter, setCurrSearchFilter] = useState("");
    const { jwt } = useOutletContext();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState("ra");

    // Update the search filter.
    const filterByText = useCallback((val, catFiltered = null) => {
        let newVal = val || "";
        if (catFiltered == null) {
            catFiltered = catFilteredResearchAreas
        }
        setCurrSearchFilter(newVal);
        if (newVal !== "") {
            let filtered = catFiltered.filter(r => {
                return r.name.toLowerCase().includes(newVal) || r.description.toLowerCase().includes(newVal);
            })
            setFilteredResearchAreas(filtered);
        } else {
            setFilteredResearchAreas(catFiltered);
        }
    }, [catFilteredResearchAreas]);

    // Update the category filter.
    const filterByCategory = useCallback((val) => {
        let newVal = val || -1;
        setCurrCatFilter(newVal);
        if (newVal >= 0) {
            DataUtil.GetAllResearchAreasByCategory(jwt, newVal).then((d) => {
                if (!d) {
                    d = [];
                }
                setCatFilteredResearchAreas(d);
                filterByText(currSearchFilter, d);
            });
        } else {
            DataUtil.GetAllResearchAreas(jwt).then((d) => {
                setCatFilteredResearchAreas(d);
                filterByText(currSearchFilter, d);
            });
        }
    }, [jwt, currSearchFilter, filterByText]);

    // Switch to the research tab.
    const switchToResearchAreasTab = useCallback((catFilter = null) => {
        DataUtil.GetAllResearchAreas(jwt).then((d) => {
            setResearchAreas(d);
            filterByCategory(currCatFilter);
        });
        if (categoryOptions.length <= 0) {
            DataUtil.GetAllResearchCategories(jwt).then((d) => {
                let cats = [];
                d.forEach(cat => {
                    cats.push({
                        id: cat.id,
                        value: cat.name
                    })
                })
                setCategoryOptions(cats);
                setResearchCategories(d);
            });
        }
        setActiveTab("ra");
    }, [jwt, categoryOptions, currCatFilter, filterByCategory])

    // Switch to the solution paradigms tab.
    const switchToSolutionParadigmsTab = () => {
        DataUtil.GetAllSolutionParadigms(jwt).then((d) => setParadigms(d));
        setActiveTab("sp");
    }

    // Switch to the research category tab.
    const switchToCategoriesTab = () => {
        DataUtil.GetAllResearchCategories(jwt).then((d) => setResearchCategories(d));
        setActiveTab("rc");
    }

    // Switch to the side-by-side tab.
    const switchToSideBySideTab = useCallback(() => {
        DataUtil.GetAllResearchAreas(jwt).then((d) => {
            setResearchAreas(d);
            setFilteredResearchAreas(d);
            setCatFilteredResearchAreas(d);
        });
        DataUtil.GetAllSolutionParadigms(jwt).then((d) => setParadigms(d));
        DataUtil.GetAllIdeaSources(jwt)
            .then((data) => setIdeaSources(data))
        setActiveTab("sbs");
    }, [jwt])

    // Switch to the idea sources tab.
    const switchToIdeaSourcesTab = () => {
        DataUtil.GetAllIdeaSources(jwt)
            .then((data) => setIdeaSources(data))
        setActiveTab("is");
    };

    // useEffect Hook
    useEffect( () => {

        if (jwt === "") {
            return;
        }

        switchToSideBySideTab();

    }, [jwt, navigate, switchToSideBySideTab])

    // Handle deletion of a research area.
    const handleResearchAreaDelete = (id) => {
        DisplayUtil.DisplayDeleteConfirmation(
            jwt, 
            id, 
            "Research Area", 
            DataUtil.DeleteResearchArea, 
            () => switchToResearchAreasTab(currCatFilter)
        );
    }

    // Handle deletion of a solution paradigm.
    const handleSolutionParadigmDelete = (id) => {
        DisplayUtil.DisplayDeleteConfirmation(
            jwt, 
            id, 
            "Solution Paradigm", 
            DataUtil.DeleteSolutionParadigm, 
            switchToSolutionParadigmsTab
        );
    }

    // Handle deletion of a research category.
    const handleResearchCategoryDelete = (id) => {
        DisplayUtil.DisplayDeleteConfirmation(
            jwt, 
            id, 
            "Research Category", 
            DataUtil.DeleteResearchCategory, 
            switchToCategoriesTab
        );
    }

    // Handle deletion of an idea source
    const handleIdeaSourceDelete = (id) => {
        DisplayUtil.DisplayDeleteConfirmation(
            jwt, 
            id, 
            "Idea Source", 
            DataUtil.DeleteIdeaSource, 
            () => switchToIdeaSourcesTab()
        );
    }

    //**************/
    // Return Content
    return (
        <div className="">

            {/* Main Heading */}
            <h1 className="display-4" >
                <img src={microscope} alt="microscope" height="42px" />
                <strong>Research</strong>
            </h1>
            <hr />

            {/* Main Content */}
            <div className="card">

                {/* Header with Tabs */}
                <ul className="nav nav-tabs card-header pb-0">
                    <li className="nav-item">
                        <button className={`nav-link link-secondary ${activeTab === "sbs" ? "active" : ""}`} href="#" onClick={switchToSideBySideTab}>
                        <img src={protractor} alt="protractor" height="24px" />&nbsp;Side-by-Side
                        </button>
                    </li>
                    <li className="nav-item">
                        <button className={`nav-link link-secondary ${activeTab === "ra" ? "active" : ""}`} onClick={switchToResearchAreasTab}>
                            <img src={telescope} alt="telescope" height="24px" />&nbsp;Research Areas
                        </button>
                    </li>
                    <li className="nav-item">
                        <button className={`nav-link link-secondary ${activeTab === "is" ? "active" : ""}`} href="#" onClick={switchToIdeaSourcesTab}>
                            <img src={books} alt="books" height="24px" />&nbsp;Idea Sources
                        </button>
                    </li>
                    <li className="nav-item">
                        <button className={`nav-link link-secondary ${activeTab === "sp" ? "active" : ""}`} href="#" onClick={switchToSolutionParadigmsTab}>
                            <img src={puzzle} alt="puzzle" height="24px" />&nbsp;Solution Paradigms
                        </button>
                    </li>
                    <li className="nav-item">
                        <button className={`nav-link link-secondary ${activeTab === "rc" ? "active" : ""}`} href="#" onClick={switchToCategoriesTab}>
                            <img src={book} alt="book" height="24px" />&nbsp;Research Categories
                        </button>
                    </li>
                </ul>

                {/* Tab Content */}
                <div className="card-body" style={{height: "72vh", overflow: activeTab === "sbs" ? "auto" : "scroll"}}>

                    {/* Research Areas Tab */}
                    { activeTab === "ra" &&
                        <>
                        
                        <div className="d-flex flex-row justify-content-end">

                            {/* Search Filter */}
                            <div className="d-flex justify-content-end">
                                <SearchFilter
                                    className={"form-control"} 
                                    name={"search-filter"}
                                    value={currSearchFilter}
                                    onChange={(evt) => filterByText(evt.target.value)}
                                    placeholder={"Filter by name or description..."}
                                    size={25}
                                />
                            </div>

                            {/* Category Filter */}
                            <div className="d-flex justify-content-end">
                                <Filter 
                                    containerStyle={{marginLeft: "10px"}}
                                    name={"categories"}
                                    value={currCatFilter}
                                    onChange={(evt) => filterByCategory(evt.target.value)}
                                    placeholder={"Filter by category..."}
                                    options={categoryOptions}
                                />
                            </div>
                        </div>

                        {/* Research Area Table */}
                        <table className="table table-light table-hover">
                            <thead className="table-dark">
                                <tr>
                                    <th scope="col">Field</th>
                                    <th scope="col" colSpan={3}>Description</th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {DisplayUtil.DisplayTableRows(
                                    filteredResearchAreas, 
                                    ["name", "description"], 
                                    ["/research-area", null],
                                    [1, 3],
                                    [40, 80],
                                    "/research-area-edit/", 
                                    handleResearchAreaDelete,
                                )}
                            </tbody>
                        </table>

                        {/* Add Button */}
                        <div className="d-flex justify-content-end">
                            <Link to={`/research-area-edit/0`} className="link-success">
                                <FontAwesomeIcon icon="fa-solid fa-square-plus" size="2x" />
                            </Link>
                        </div>

                        </>
                    }

                    {/* Solution Paradigms Tab */}
                    { activeTab === "sp" &&
                        <>
                        <table className="table table-hover">
                            <thead className="table-dark">
                                <tr>
                                    <th scope="col">Paradigm</th>
                                    <th scope="col" colSpan={3}>Description</th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {DisplayUtil.DisplayTableRows(
                                    paradigms, 
                                    ["name", "description"], 
                                    ["/solution-paradigm", null],
                                    [1, 3],
                                    [40, 80],
                                    "/solution-paradigm-edit/", 
                                    handleSolutionParadigmDelete
                                )}
                            </tbody>
                        </table>
                        <div className="d-flex justify-content-end">
                            <Link to={`/solution-paradigm-edit/0`} className="link-success">
                                <FontAwesomeIcon icon="fa-solid fa-square-plus" size="2x" />
                            </Link>
                        </div>

                        </>
                    }

                    {/* Side-by-side */}
                    { activeTab === "sbs" &&
                        <>
                        <div className="row" style={{height: "100%"}}>
                            <div className="col-sm" style={{height: "100%", paddingRight: "0"}}>
                                <div className="container card p-2" style={{height: "100%", overflow: "scroll"}}>
                                
                                    <table className="table table-light table-hover">
                                        <thead className="table-dark">
                                            <tr>
                                                <th scope="col">Field</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {DisplayUtil.DisplayTableRows(
                                                researchAreas, 
                                                ["name"], 
                                                ["/research-area"],
                                                [1],
                                                [40],
                                                "/research-area-edit/", 
                                            )}
                                        </tbody>
                                    </table>
                                    <div className="d-flex justify-content-end">
                                        <Link to={`/research-area-edit/0`} className="link-success">
                                            <FontAwesomeIcon icon="fa-solid fa-square-plus" size="2x" />
                                        </Link>
                                    </div>

                                </div>
                            </div>
                            <div className="col-sm" style={{height: "100%"}}>
                                <div className="container card p-2" style={{height: "50%", overflow: "scroll"}}>
                                
                                    <table className="table table-light table-hover">
                                        <thead className="table-dark">
                                            <tr>
                                                <th scope="col">Idea Source</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {DisplayUtil.DisplayTableRows(
                                                ideaSources, 
                                                ["name"], 
                                                ["/idea-source"],
                                                [1],
                                                [40],
                                                "/idea-source-edit/",
                                            )}
                                        </tbody>
                                    </table>
                                    <div className="d-flex justify-content-end">
                                        <Link to={`/idea-source-edit/0`} className="link-success">
                                            <FontAwesomeIcon icon="fa-solid fa-square-plus" size="2x" />
                                        </Link>
                                    </div>

                                </div>
                                <div className="container card p-2" style={{height: "50%", overflow: "scroll"}}>
                                
                                    <table className="table table-light table-hover">
                                        <thead className="table-dark">
                                            <tr>
                                                <th scope="col">Paradigm</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {DisplayUtil.DisplayTableRows(
                                                paradigms, 
                                                ["name"], 
                                                ["/solution-paradigm"],
                                                [1],
                                                [40],
                                                "/solution-paradigm-edit/",
                                            )}
                                        </tbody>
                                    </table>
                                    <div className="d-flex justify-content-end">
                                        <Link to={`/solution-paradigm-edit/0`} className="link-success">
                                            <FontAwesomeIcon icon="fa-solid fa-square-plus" size="2x" />
                                        </Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                        </>
                    }

                    {/* Research Categories Tab */}
                    { activeTab === "rc" &&
                        <>
                        <table className="table table-hover">
                            <thead className="table-dark">
                                <tr>
                                    <th scope="col">Category</th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {DisplayUtil.DisplayTableRows(
                                    researchCategories, 
                                    ["name"], 
                                    ["/research-category"],
                                    [1],
                                    null,
                                    "/research-category-edit/", 
                                    handleResearchCategoryDelete
                                )}
                            </tbody>
                        </table>
                        <div className="d-flex justify-content-end">
                            <Link to={`/research-category-edit/0`} className="link-success">
                                <FontAwesomeIcon icon="fa-solid fa-square-plus" size="2x" />
                            </Link>
                        </div>

                        </>
                    }

                    {/* Idea Sources Tab */}
                    { activeTab === "is" &&
                        <>

                        {/* Main Table */}
                        <table className="table table-light table-hover">
                            <thead className="table-dark">
                                <tr>
                                    <th scope="col" colSpan={1}>Name</th>
                                    <th scope="col" colSpan={3}>Summary</th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {DisplayUtil.DisplayTableRows(
                                    ideaSources, 
                                    ["name", "summary"], 
                                    ["/idea-source", null],
                                    [1, 3],
                                    [40, 80],
                                    "/idea-source-edit/", 
                                    handleIdeaSourceDelete,
                                )}
                            </tbody>
                        </table>

                        {/* Add Button */}
                        <div className="d-flex justify-content-end">
                            <Link to={`/idea-source-edit/0`} className="link-success">
                                <FontAwesomeIcon icon="fa-solid fa-square-plus" size="2x" />
                            </Link>
                        </div>

                        </>
                    }
                </div>
            </div>
            
        </div>
    )
}

export default Research;