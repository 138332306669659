
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as MiscUtil from './misc';

export function DisplayTableRows(
    data, 
    columns, 
    colLinks = null, 
    colSpans = null, 
    colTrunc = null, 
    editLink = null, 
    handleDelete = null) {

    // console.log("Displaying rows...");
    // console.log("Data:", data);
    // console.log("Columns:", columns);

    if (!data) {
        return;
    }

    return (
        data.map(d => (
            <tr key={d.id}>
                {columns.map((c, idx) => {

                    let val = d[c];
                    if (colTrunc && colTrunc[idx] > 0) {
                        val = MiscUtil.truncateString(d[c], colTrunc[idx]);
                    }

                    let content = val;
                    if (colLinks && colLinks[idx] != null) {
                        if (typeof colLinks[idx] === "string") {
                            content = (
                                <Link to={`${colLinks[idx]}/${d.id}`} className="link-dark">
                                    {val}
                                </Link>
                            );
                        } else {
                            content = (
                                <Link to={`${colLinks[idx].value}/${d[colLinks[idx].id]}`} className="link-dark">
                                    {val}
                                </Link>
                            );
                        }
                    }

                    return (
                        <td key={d.id + "." + c} colSpan={colSpans[idx] == null ? 1 : colSpans[idx]}>
                            {content}
                        </td>
                    )
                })}
                {editLink !== null && (
                    <td style={{width: "25px"}}>
                        <Link to={editLink + d.id} className="link-secondary">
                            <FontAwesomeIcon icon="fa-solid fa-pencil" size="lg" />
                        </Link>
                    </td>
                )}
                {handleDelete !== null && (
                    <td style={{width: "25px"}}>
                        <a href="#!" onClick={() => handleDelete(d.id)} className="link-danger">
                            <FontAwesomeIcon icon="fa-solid fa-trash" size="lg" />
                        </a>
                    </td>
                )}
            </tr>
        ))
    );
}

export function DisplayDeleteConfirmation(jwt, id, niceName, deleteFunc, handleSuccess) {

    Swal.fire({
        title: `Delete ${niceName}?`,
        text: "You cannot undo this action!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085D6",
        cancelButtonColor: "#D33",
        confirmButtonText: "Yes, delete it!"
    }).then((result) => {

        if (result.isConfirmed) {

            return deleteFunc(
                jwt, 
                id,
                (data) => {
                    Swal.fire(
                        "Deleted!",
                        `${niceName} has been deleted.`,
                        "success"
                    ).then(() => {
                        handleSuccess(data);
                    });
                },
                (data) => {
                    Swal.fire(
                        "Error!",
                        "There was a problem processing the delete request.",
                        "error"
                    );
                    console.log(data.error);
                }
            );
        }
    })
}

export function displayRankingStars(count) {
    let stars = []
    for (let i = 0; i < 5; i++) {
        if (i < count) {
            stars.push(<FontAwesomeIcon key={i} icon="fa-solid fa-star" color="#dbc327" />)
        } else {
            stars.push(<FontAwesomeIcon key={i} icon="fa-regular fa-star" color="#dbc327" />)
        }
    }
    return stars;
}