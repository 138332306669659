import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import App from './App';
import ErrorPage from './components/pages/ErrorPage';
import Home from './components/pages/Home';
import Login from './components/pages/Login';
import Research from './components/pages/Research';
import Experiment from './components/pages/Experiment';
import Inventions from './components/pages/Invent';
import GraphQL from './components/pages/GraphQL';
import ResearchAreaView from './components/view/ResearchAreaView';
import ResearchAreaEdit from './components/edit/ResearchAreaEdit';
import SolutionParadigmEdit from './components/edit/SolutionParadigmEdit';
import SolutionParadigmView from './components/view/SolutionParadigmView';
import IdeaSourceEdit from './components/edit/IdeaSourceEdit';
import IdeaSourceView from './components/view/IdeaSourceView';
import ExperimentView from './components/view/ExperimentView';
import ExperimentEdit from './components/edit/ExperimentEdit';
import InventionView from './components/view/InventionView';
import InventionEdit from './components/edit/InventionEdit';
import ResearchCategoryEdit from './components/edit/ResearchCategoryEdit';
import ResearchCategoryView from './components/view/ResearchCategoryView';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {index: true, element: <Home />},
      {
        path: "/research",
        element: <Research />,
      },
      {
        path: "/research-area/:id",
        element: <ResearchAreaView />,
      },
      {
        path: "/research-category/:id",
        element: <ResearchCategoryView />,
      },
      {
        path: "/research-area-edit/:id",
        element: <ResearchAreaEdit />,
      },
      {
        path: "/research-category-edit/:id",
        element: <ResearchCategoryEdit />,
      },
      {
        path: "/solution-paradigm-edit/:id",
        element: <SolutionParadigmEdit />,
      },
      {
        path: "/solution-paradigm/:id",
        element: <SolutionParadigmView />,
      },
      {
        path: "/experiment",
        element: <Experiment />,
      },
      {
        path: "/experiment/:id",
        element: <ExperimentView />,
      },
      {
        path: "/experiment-edit/:id",
        element: <ExperimentEdit />,
      },
      {
        path: "/experiment-edit/:id/pain-point/:pp_id",
        element: <ExperimentEdit />,
      },
      {
        path: "/idea-source/:id",
        element: <IdeaSourceView />,
      },
      {
        path: "/idea-source-edit/:id",
        element: <IdeaSourceEdit />,
      },
      {
        path: "/invent",
        element: <Inventions />,
      },
      {
        path: "/invention/:id",
        element: <InventionView />,
      },
      {
        path: "/invention-edit/:id",
        element: <InventionEdit />,
      },
      {
        path: "/invention-edit/:id/experiment/:e_id",
        element: <InventionEdit />,
      },
      {
        path: "/graphql",
        element: <GraphQL />,
      },
      {
        path: "/login",
        element: <Login />,
      }
    ]
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);