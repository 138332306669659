import { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext, useParams } from "react-router-dom";
import Input from "../form/Input";
import TextArea from "../form/TextArea";
import Checkbox from "../form/Checkbox";
import Swal from "sweetalert2";
import * as DataUtil from './../../util/data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MarkdownEditor from "../form/MarkdownEditor";

const ResearchAreaEdit = () => {

    const navigate = useNavigate();
    const { jwt } = useOutletContext();

    // const [error, setError] = useState("");
    const [errors, setErrors] = useState([]);

    const hasError = (key) => {
        return errors.indexOf(key) !== -1;
    }

    const emptyResearchArea = () => ({
        id: 0,
        name: "",
        description: "",
        notes: "",
        categories: [],
        categoriesArray: []
    })
    const [researchArea, setResearchArea] = useState(emptyResearchArea());
    const [categories, setCategories] = useState([]);

    // Get ID from the URL.
    let {id} = useParams();

    const loadEmpty = () => {

        setResearchArea(r => (emptyResearchArea()));

        DataUtil.GetAllResearchCategories(jwt)
            .then((data) => {
                const checks = [];
                let catArr = [];
                data.forEach(c => {
                    checks.push({id: c.id, checked: false, name: c.name});
                    catArr.push({id: c.id, name: c.name});
                });

                setResearchArea(r => ({
                    ...r,
                    categories: checks,
                }))
                setCategories(catArr);
            })
            .catch(err => {
                console.log(err);
            })
    }

    useEffect(() => {

        if (!jwt || jwt === "") {
            return;
        }

        if (id === "0") {

            // We're adding a new research area.
            setResearchArea(r => (emptyResearchArea()));

            DataUtil.GetAllResearchCategories(jwt)
                .then((data) => {
                    const checks = [];
                    let catArr = [];
                    data.forEach(c => {
                        checks.push({id: c.id, checked: false, name: c.name})
                        catArr.push({id: c.id, name: c.name})
                    });

                    setResearchArea(r => ({
                        ...r,
                        categories: checks,
                    }))
                    setCategories(catArr);
                })
                .catch(err => {
                    console.log(err);
                })

        } else {

            // We're editing an existing research area.
            DataUtil.GetResearchArea(jwt, id)
                .then((raData) => {

                    // Remember to fix any data types that won't be the same.
                    // e.g. data.research_area.release_date = 
                    //          new Date(data.research_area.release_date).toISOString().split('T')[0]

                    if (!raData.categoriesArray) {
                        raData.categoriesArray = [];
                    }

                    DataUtil.GetAllResearchCategories(jwt)
                        .then((cData) => {
                            const checks = [];
                            let catArr = [];

                            cData.forEach(c => {
                                checks.push({
                                    id: c.id, 
                                    name: c.name,
                                    checked: raData.categoriesArray.indexOf(c.id) !== -1});
                                catArr.push({id: c.id, name: c.name})
                            });
                            setCategories(catArr);
                            setResearchArea({
                                ...raData,
                                categories: checks
                            });
                        });
                });
        }

    }, [jwt, navigate, id]);

    const handleSubmit = (evt) => {

        evt.preventDefault();

        // Handle validation.
        let errors = [];
        let required = [
            { field: researchArea.name, name: "name"},
            { field: researchArea.description, name: "description"},
        ];

        required.forEach(function (obj) {
            if (obj.field === "") {
                errors.push(obj.name);
            }
        });

        if (researchArea.categoriesArray.length <= 0) {
            // Fancy alert.
            Swal.fire({
                title: 'Uh oh!',
                text: "You need to select at least one research category!",
                icon: 'error',
                confirmButtonText: 'OK',
            })
            errors.push("categories");
        }

        setErrors(errors);

        if (errors.length > 0) {
            return false;
        }

        // Passed validation, so save changes.
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", "Bearer " + jwt);

        // Assume we're adding a new area.
        let method = "PUT";
        if (researchArea.id > 0) {
            method = "PATCH";
        }

        const requestBody = researchArea;
        // Don't forget to convert from a string if the value isn't a string.
        // e.g.: requestBody.release_date = new Date(researchArea.release_date);

        let requestOptions = {
            body: JSON.stringify(requestBody),
            method: method,
            headers: headers,
            credentials: "include",
        }

        fetch(`${process.env.REACT_APP_BACKEND}/api/research-areas/${researchArea.id}`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.error) {
                    console.log(data.error);
                    Swal.fire({
                        title: 'Uh oh!',
                        text: "There was an error adding the data :(",
                        icon: 'error',
                        confirmButtonText: 'Oh, ok',
                    })
                } else {
                    if (researchArea.id > 0) {
                        navigate(`/research-area/${researchArea.id}`);
                    } else {
                        Swal.fire({
                            title: 'Added',
                            text: "New Research Area Added!",
                            icon: 'success',
                            confirmButtonText: 'Great!',
                        }).then(() => loadEmpty());
                    }
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleChange = () => (evt) => {
        let value = evt.target.value;
        let name = evt.target.name;
        setResearchArea({
            ...researchArea,
            [name]: value
        })
    }
    const handleChangeMarkdown = (name, val) => {
        setResearchArea({
            ...researchArea,
            [name]: val
        })
    }

    const handleCategoryOptionCheck = (evt, idx) => {

        let tmpArr = researchArea.categories;
        tmpArr[idx].checked = !tmpArr[idx].checked;
        let tmpIDs = researchArea.categoriesArray;
        if (!evt.target.checked) {
            tmpIDs.splice(tmpIDs.indexOf(parseInt(evt.target.value, 10)), 1);
        } else {
            tmpIDs.push(parseInt(evt.target.value, 10));
        }

        setResearchArea({
            ...researchArea,
            categoriesArray: tmpIDs
        })
    }

    const confirmDelete = () => {
        Swal.fire({
            title: "Delete Research Area?",
            text: "You cannot undo this action!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085D6",
            cancelButtonColor: "#D33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                let headers = new Headers();
                headers.append("Authorization", "Bearer " + jwt);
                const requestOptions = {
                    method: "DELETE",
                    headers: headers,
                }
                fetch(`${process.env.REACT_APP_BACKEND}/api/research-areas/${researchArea.id}`, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            Swal.fire(
                                "Error!",
                                "There was a problem processing the delete request.",
                                "error"
                            );
                            console.log(data.error);
                        } else {
                            Swal.fire(
                                "Deleted!",
                                "Research Area has been deleted.",
                                "success"
                            ).then(() => {
                                navigate("/research");
                            });
                        }
                    })
                    .catch(err => { console.log(err); });
            }
        })
    }

    // if (error !== null && error !== "") {
    //     return <div>Error: {error.message}</div>;
    // } else {
        return (
            <div className="">
                {researchArea.id > 0 &&
                    <div className="float-end">
                        <a href="#!" className="btn btn-danger ms-2" onClick={confirmDelete}>
                            <FontAwesomeIcon icon="fa-solid fa-trash" size="1x" />
                        </a>
                    </div>
                }
                {id <= 0 &&
                    <h2 className="display-6">
                        Add a Research Area
                    </h2>
                }
                {id > 0 &&
                    <h2 className="display-6">
                        Edit Research Area
                    </h2>
                }
                <hr />
                {/* <pre>{JSON.stringify(researchArea, null, 3)}</pre> */}

                <form onSubmit={handleSubmit}>

                    <input type="hidden" id="id" name="id" value={researchArea.id}></input>

                    <Input 
                        title={"Name"}
                        className={"form-control"}
                        type={"text"}
                        name={"name"}
                        value={researchArea.name}
                        onChange={handleChange("name")}
                        errorDiv={hasError("name") ? "text-danger" : "d-none"}
                        errorMsg={"Please enter a name"}
                        maxLength={255}
                    />

                    <TextArea 
                        title={"Description"}
                        className={"form-control"}
                        name={"description"}
                        rows={2}
                        value={researchArea.description}
                        onChange={handleChange("description")}
                        errorDiv={hasError("description") ? "text-danger" : "d-none"}
                        errorMsg={"Please enter a description"}
                        maxLength={512}
                    />

                    <MarkdownEditor 
                        title={"Notes"}
                        className={"form-control"}
                        name={"notes"}
                        rows={5}
                        value={researchArea.notes}
                        onChange={(val) => handleChangeMarkdown("notes", val)}
                        errorDiv={hasError("notes") ? "text-danger" : "d-none"}
                        errorMsg={"Please enter some notes"}
                    />

                    <h5>Research Categories</h5>

                    {categories && categories.length > 1 &&
                        <>
                            {categories.map((c, idx) => (
                                <Checkbox
                                    title={c.name}
                                    name={"research-category"}
                                    key={idx}
                                    id={"category-" + idx}
                                    onChange={(evt) => handleCategoryOptionCheck(evt, idx)}
                                    value={c.id}
                                    checked={researchArea.categories[idx]?.checked || false}
                                />
                            ))}
                        </>
                    }

                    <hr />
                    <div className="float-end">
                        {parseInt(id) === 0 &&
                            <Link to={`/research`} className="btn btn-secondary justify-content-end"
                                style={{marginRight: "10px"}}>
                                Cancel
                            </Link>
                        }
                        {parseInt(id) > 0 &&
                            <Link to={`/research-area/${researchArea?.id}`} className="btn btn-secondary justify-content-end"
                                style={{marginRight: "10px"}}>
                                Cancel
                            </Link>
                        }
                        <button className="btn btn-primary" type="submit">
                            {researchArea.id !== 0 &&
                            <span>Save</span>
                            }
                            {researchArea.id === 0 &&
                            <span>Save & Add Another</span>
                            }
                        </button>
                    </div>

                </form>
                <br/>
                
            </div>
        )
    // }
}

export default ResearchAreaEdit;