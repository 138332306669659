import { useEffect, useState } from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { Link, useOutletContext, useParams, useNavigate } from 'react-router-dom';
import dna from './../../images/dna.svg'
import * as DataUtil from '../../util/data';
import Input from "../form/Input";
import MarkdownEditor from "../form/MarkdownEditor";
import * as DisplayUtil from '../../util/display';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import MDEditor from '@uiw/react-md-editor';

const ExperimentView = () => {

    let { jwt } = useOutletContext();
    let { id } = useParams();

    const [experiment, setExperiment] = useState({});
    const [paradigm, setParadigm] = useState([]);
    const [painPoint, setPainPoint] = useState([]);
    const [, setTradeoffs] = useState([]);
    const [pros, setPros] = useState([]);
    const [cons, setCons] = useState([]);
    const [valueAttributes, setValueAttributes] = useState([]);
    
    const [editingTradeoff, setEditingTradeoff] = useState(-1);
    const [editingTradeoffType, setEditingTradeoffType] = useState("");
    const [editingTradeoffVal, setEditingTradeoffVal] = useState("");
    const [editingAttribute, setEditingAttribute] = useState(-1);
    const [editingAttributeValue, setEditingAttributeValue] = useState("");

    const [editingNotes, setEditingNotes] = useState(false);
    const [editedNotes, setEditedNotes] = useState("");
    const [notesHeadingHovered, setNotesHeadingHovered] = useState(false);

    const navigate = useNavigate();

    // Select a tradeoff for edit
    const selectTradeoffForEdit = (id, startVal, type) => {
        setEditingTradeoff(id);
        setEditingTradeoffType(type);
        setEditingTradeoffVal(startVal);
    }

    // Select a value attribute for edit
    const selectAttributeForEdit = (id, startVal) => {
        setEditingAttribute(id);
        setEditingAttributeValue(startVal);
    }

    // Cancel editing a tradeoff
    const cancelTradeoffEdit = () => {
        setEditingTradeoff(-1);
        setEditingTradeoffType("");
        setEditingTradeoffVal("");
    }

    // Cancel editing a value attribute
    const cancelAttributeEdit = () => {
        setEditingAttribute(-1);
        setEditingAttributeValue("");
    }

    // Change an edited tradeoffs's value
    const handleTradeoffEditChange = (evt) => {
        setEditingTradeoffVal(evt.target.value);
    }

    // Change an edited attribute's value
    const handleAttributeEditChange = (evt) => {
        setEditingAttributeValue(evt.target.value);
    }

    // Save changes to a tradeoff
    const saveTradeoff = (tradeoff, type) => {
        tradeoff.summary = editingTradeoffVal;

        if (tradeoff.id > 0) {
            DataUtil.UpdateTradeoff(jwt, tradeoff);
        } else if (tradeoff.id === 0) {
            let payload = {
                ...tradeoff,
                experiment_id: parseInt(experiment.id),
                type: type.toUpperCase()
            }
            DataUtil.InsertTradeoff(jwt, payload)
                .then(() => {
                    DataUtil.GetAllTradeoffsByExperiment(jwt, id)
                        .then(d => { 
                            setTradeoffs(d);
                            setPros(d?.filter(i => i.type.toUpperCase() === "PRO"));
                            setCons(d?.filter(i => i.type.toUpperCase() === "CON"));
                        });
                });
        }
        cancelTradeoffEdit();
    }

    // Save changes to a value attribute
    const saveAttribute = (attr) => {
        attr.summary = editingAttributeValue;
        attr.experiment_id = parseInt(attr.experiment_id);

        if (attr.id > 0) {
            DataUtil.UpdateValueAttribute(jwt, attr);
        } else if (attr.id === 0) {
            DataUtil.InsertValueAttribute(jwt, attr)
                .then(() => DataUtil.GetAllValueAttributesByExperiment(jwt, id).then(d => setValueAttributes(d)));
        }
        cancelAttributeEdit();
    }

    // Edit notes
    const editNotes = () => {
        setEditedNotes(experiment.notes);
        setEditingNotes(true);
    }

    // Cancel editing notes
    const cancelEditingNotes = () => {
        setEditedNotes("");
        setEditingNotes(false);
    }

    // Save changes to notes
    const saveExperiment = () => {
        setExperiment({
            ...experiment,
            notes: editedNotes
        })
        DataUtil.UpdateExperiment(jwt, {...experiment, notes: editedNotes});
        cancelEditingNotes();
    }

    // Handle deletion of a tradeoff
    const handleTradeoffDelete = (id) => {
        DisplayUtil.DisplayDeleteConfirmation(
            jwt, 
            id, 
            "Trade-off", 
            DataUtil.DeleteTradeoff, 
            () => {
                DataUtil.GetAllTradeoffsByExperiment(jwt, experiment.id)
                    .then(d => { 
                        setTradeoffs(d);
                        setPros(d?.filter(i => i.type.toUpperCase() === "PRO"));
                        setCons(d?.filter(i => i.type.toUpperCase() === "CON"));
                    });
                cancelTradeoffEdit();
            }
        );
    }

    // Handle deletion of a value attribute
    const handleAttributeDelete = (id) => {
        DisplayUtil.DisplayDeleteConfirmation(
            jwt, 
            id, 
            "Value Attribute", 
            DataUtil.DeleteValueAttribute, 
            (data) => {
                DataUtil.GetAllValueAttributesByExperiment(jwt, experiment.id).then(d => setValueAttributes(d));
                cancelAttributeEdit();
            }
        );
    }

    // Get JSX for an editable value attribute.
    const displayAttributeEdit = (attr) => (
        <li key={attr.id} className="list-group-item d-flex justify-content-between align-items-start">
            <div className="d-flex flex-row w-100">
                <div className="flex-grow-1 justify-content-start">
                    <Input 
                        title={"Summary"}
                        type={"text"}
                        name={"Value Attribute Summary"}
                        className={"form-control"}
                        value={editingAttributeValue}
                        onChange={(evt) => handleAttributeEditChange(evt)}
                        hideLabel={true}
                        containerClassName={"mr-0"}
                        style={{}}
                        maxLength={512}
                    />
                </div>
                <button type="button" className="btn btn-secondary justify-content-end"
                    onClick={cancelAttributeEdit} style={{marginLeft: "10px"}}>
                    <FontAwesomeIcon icon="fa-solid fa-xmark" />
                </button>
                <button type="button" className="btn btn-primary justify-content-end" 
                    onClick={() => saveAttribute(attr)} style={{marginLeft: "10px"}}>
                    <FontAwesomeIcon icon="fa-solid fa-check" />
                </button>
                {attr.id !== 0 &&
                <button type="button" className="btn btn-danger justify-content-end" 
                    onClick={() => handleAttributeDelete(attr.id)} style={{marginLeft: "10px"}}>
                    <FontAwesomeIcon icon="fa-solid fa-trash" />
                </button>
                }
            </div>
        </li>
    );

    // Get JSX for an editable tradeoff.
    const displayTradeoffEdit = (attr, type) => (
        <li key={attr.id} className="list-group-item d-flex justify-content-between align-items-start">
            <div className="d-flex flex-row w-100">
                <div className="flex-grow-1 justify-content-start">
                    <Input 
                        title={"Summary"}
                        type={"text"}
                        name={`${type} Summary`}
                        className={"form-control"}
                        value={editingTradeoffVal}
                        onChange={(evt) => handleTradeoffEditChange(evt)}
                        hideLabel={true}
                        containerClassName={"mr-0"}
                        style={{}}
                        maxLength={255}
                    />
                </div>
                <button type="button" className="btn btn-secondary justify-content-end"
                    onClick={cancelTradeoffEdit} style={{marginLeft: "10px"}}>
                    <FontAwesomeIcon icon="fa-solid fa-xmark" />
                </button>
                <button type="button" className="btn btn-primary justify-content-end" 
                    onClick={() => saveTradeoff(attr, type)} style={{marginLeft: "10px"}}>
                    <FontAwesomeIcon icon="fa-solid fa-check" />
                </button>
                {attr.id !== 0 &&
                <button type="button" className="btn btn-danger justify-content-end" 
                    onClick={() => handleTradeoffDelete(attr.id)} style={{marginLeft: "10px"}}>
                    <FontAwesomeIcon icon="fa-solid fa-trash" />
                </button>
                }
            </div>
        </li>
    );

    // An empty tradeoff used for adding new ones.
    const emptyTradeoff = {
        id: 0,
        summary: "",
        experiment_id: id,
    };

    // An empty value attribute used for adding new ones.
    const emptyAttribute = {
        id: 0,
        summary: "",
        experiment_id: id,
    }

    // useEffect Hook
    useEffect(() => {

        if (!jwt || jwt === "") {
            return;
        }

        if (id < 1) {
            return;
        }

        DataUtil.GetAllTradeoffsByExperiment(jwt, id)
            .then(d => { 
                setTradeoffs(d);
                setPros(d?.filter(i => i.type.toUpperCase() === "PRO"));
                setCons(d?.filter(i => i.type.toUpperCase() === "CON"));
            });
        DataUtil.GetAllValueAttributesByExperiment(jwt, id).then(d => setValueAttributes(d));
        DataUtil.GetExperiment(jwt, id).then(d => {
            setExperiment(d);
            DataUtil.GetSolutionParadigm(jwt, d.solution_paradigm_id).then(d => setParadigm(d));
            DataUtil.GetPainPoint(jwt, d.pain_point_id).then(d => setPainPoint(d));
        });
        
    }, [id, jwt])

    // Return for no data
    if (!jwt || !experiment) {
        return <>Nothing here!</>
    }

    // *****************************
    // Main return
    return (
        <div className="row">
            <div className="col-md-12 pb-3" style={{height: "90vh", overflow: "scroll"}}>

                {/* Heading */}
                <div className="float-end mt-3">
                    <Link to={`/experiment-edit/${id}`} className="link-secondary">
                        <FontAwesomeIcon icon="fa-solid fa-pen-to-square" size="2x" />
                    </Link>
                </div>
                <h4 className="display-6" style={{marginBottom: "-0px", fontSize: "32px"}}>
                    <img src={dna} alt="dna" height="28px" width="28px" style={{marginBottom: "10px"}} />
                    Experiment:
                </h4>
                <h1 className="display-5" style={{lineHeight: "36px", fontSize: "36px"}}>
                    <strong>{experiment?.summary}</strong>
                </h1>

                <hr/>
                <p className="lead text-center">
                    <span style={{textTransform: ""}}>
                        A <Link 
                            to={`/solution-paradigm/${paradigm.id}`}
                            className="link-info" 
                            style={{textTransform: "lowercase", fontWeight: "500", textDecoration: "none"}}>
                            {paradigm.name}
                        </Link> solution for the problem:<br/>
                    </span>
                    <Link 
                        to={`/research-area/${painPoint.research_area_id}`}
                        className="link-dark" 
                        style={{fontWeight: "500", textDecoration: "none"}}>
                        {painPoint?.summary}
                    </Link>
                    {/* <span style={{fontWeight: "500"}}>{painPoint?.summary}</span> */}
                </p>
                <hr />

                {/* Value Attributes */}
                <h3 className="display-6" style={{fontSize: "28px"}}>Value Skew Attributes:</h3>
                <ol className="list-group">

                    {/* All existing value attributes */}
                    {valueAttributes && valueAttributes.map(attr => {

                        let isEditing = editingAttribute === attr.id;

                        if (isEditing) {

                            // Editing an attribute
                            return displayAttributeEdit(attr);
                            
                        } else {
                            
                            // Viewing an attribute
                            return (
                                <li key={attr.id} 
                                    className="list-group-item d-flex justify-content-between align-items-start"
                                    onClick={() => selectAttributeForEdit(attr.id, attr.summary)}>
                                    <div className="me-auto">
                                    {attr.summary}
                                    </div>
                                </li>
                            );
                        }
                    })}

                    {/* New attribute */}
                    {editingAttribute === 0 &&
                        displayAttributeEdit(emptyAttribute)
                    }
                    {editingAttribute !== 0 && (
                    <a href="!#" onClick={(evt) => { 
                        evt.preventDefault();
                        selectAttributeForEdit(0, "");
                     }} 
                        className="list-group-item list-group-item-success">
                        {(!valueAttributes || valueAttributes.length <= 0) && 
                            <span>There's nothing here yet!&nbsp;</span>
                        }
                        Add a new one!
                    </a>
                    )}
                </ol>
                <hr />

                {/* Tradeoffs */}
                <div className="row">
                    <div className="col-md-6">
                        <h3 className="display-6" style={{fontSize: "28px"}}>Pros:</h3>
                            <ol className="list-group">

                                {/* All existing pro tradeoffs */}
                                {pros && pros.map(pro => {

                                    let isEditing = editingTradeoff === pro.id;

                                    if (isEditing) {

                                        // Editing a tradeoff
                                        return displayTradeoffEdit(pro);
                                        
                                    } else {
                                        
                                        // Viewing a tradeoff
                                        return (
                                            <li key={pro.id} 
                                                className="list-group-item d-flex justify-content-between align-items-start"
                                                onClick={() => selectTradeoffForEdit(pro.id, pro.summary, "PRO")}>
                                                <div className="me-auto">
                                                {pro.summary}
                                                </div>
                                            </li>
                                        );
                                    }
                                })}

                                {/* New pro tradeoff */}
                                {editingTradeoff === 0 && editingTradeoffType.toUpperCase() === "PRO" &&
                                    displayTradeoffEdit(emptyTradeoff, "PRO")
                                }
                                {(editingTradeoff !== 0 || editingTradeoffType.toUpperCase() !== "PRO") && (
                                <a href="!#" onClick={(evt) => { 
                                    evt.preventDefault();
                                    selectTradeoffForEdit(0, "", "PRO");
                                }} 
                                    className="list-group-item list-group-item-success">
                                    {(!pros || pros.length <= 0) && 
                                        <span>There's nothing here yet!&nbsp;</span>
                                    }
                                    Add a new one!
                                </a>
                                )}
                            </ol>
                        </div>
                    <div className="col-md-6">
                        <h3 className="display-6" style={{fontSize: "28px"}}>Cons:</h3>
                            <ol className="list-group">

                                {/* All existing con tradeoffs */}
                                {cons && cons.map(con => {

                                    let isEditing = editingTradeoff === con.id;

                                    if (isEditing) {

                                        // Editing a tradeoff
                                        return displayTradeoffEdit(con);
                                        
                                    } else {
                                        
                                        // Viewing a tradeoff
                                        return (
                                            <li key={con.id} 
                                                className="list-group-item d-flex justify-content-between align-items-start"
                                                onClick={() => selectTradeoffForEdit(con.id, con.summary, "CON")}>
                                                <div className="me-auto">
                                                {con.summary}
                                                </div>
                                            </li>
                                        );
                                    }
                                })}

                                {/* New con tradeoff */}
                                {editingTradeoff === 0 && editingTradeoffType.toUpperCase() === "CON" &&
                                    displayTradeoffEdit(emptyTradeoff, "CON")
                                }
                                {(editingTradeoff !== 0 || editingTradeoffType.toUpperCase() !== "CON") && (
                                <a href="!#" onClick={(evt) => { 
                                    evt.preventDefault();
                                    selectTradeoffForEdit(0, "", "CON");
                                }} 
                                    className="list-group-item list-group-item-success">
                                    {(!cons || cons.length <= 0) && 
                                        <span>There's nothing here yet!&nbsp;</span>
                                    }
                                    Add a new one!
                                </a>
                                )}
                            </ol>
                        </div>
                    </div>
                <hr />

                {/* CENTS Rankings */}
                <h3 className="display-6" style={{fontSize: "28px"}}>Rankings:</h3>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-2">
                                <h3 className="display-6" style={{fontSize: "18px"}}>Control</h3>
                                {DisplayUtil.displayRankingStars(experiment.rank_control)}
                            </div>
                            <div className="col-md-2">
                                <h3 className="display-6" style={{fontSize: "18px"}}>Entry</h3>
                                {DisplayUtil.displayRankingStars(experiment.rank_entry)}
                            </div>
                            <div className="col-md-2">
                                <h3 className="display-6" style={{fontSize: "18px"}}>Need</h3>
                                {DisplayUtil.displayRankingStars(experiment.rank_need)}
                            </div>
                            <div className="col-md-2">
                                <h3 className="display-6" style={{fontSize: "18px"}}>Time</h3>
                                {DisplayUtil.displayRankingStars(experiment.rank_time)}
                            </div>
                            <div className="col-md-2">
                                <h3 className="display-6" style={{fontSize: "18px"}}>Scale</h3>
                                {DisplayUtil.displayRankingStars(experiment.rank_scale)}
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                
                {/* Notes */}
                <h3 className="display-6"  style={{fontSize: "28px"}}
                    onMouseEnter={() => setNotesHeadingHovered(true)} 
                    onMouseLeave={() => setNotesHeadingHovered(false)}>
                    Notes&nbsp;
                    {notesHeadingHovered && ! editingNotes &&
                        <button type="button" className="btn btn-warning" style={{padding: "0px 5px"}}
                            onClick={() => editNotes()}>
                            <FontAwesomeIcon icon="fa-solid fa-pen" size="xs" />
                        </button>
                    }
                </h3>
                {!editingNotes &&
                    <div className="card p-3 pb-0" onClick={(evt) => {if (evt.detail > 2) {editNotes();}}}>
                        {experiment?.notes !== "" &&
                            <ReactMarkdown children={experiment?.notes}/>
                        }
                        {experiment?.notes === "" &&
                            <div className="pb-3" style={{color: "#999", fontStyle: "italic"}}>No notes on this experiment yet.</div>
                        }
                    </div>
                }
                {editingNotes &&
                <>
                    <MarkdownEditor
                        title={"Notes"}
                        className={"form-control"}
                        name={"notes"}
                        value={editedNotes}
                        onChange={(newVal) => {
                            setEditedNotes(newVal)
                        }}
                        preview="edit"
                        hideLabel={true}
                    />
                    <div className="float-end mt-3">
                        <button type="button" className="btn btn-secondary justify-content-end"
                            onClick={() => cancelEditingNotes()} style={{marginLeft: "10px"}}>
                            <FontAwesomeIcon icon="fa-solid fa-xmark" />
                        </button>
                        <button type="button" className="btn btn-primary justify-content-end" 
                            onClick={() => saveExperiment(experiment)} style={{marginLeft: "10px"}}>
                            <FontAwesomeIcon icon="fa-solid fa-check" />
                        </button>
                    </div>
                </>
                }
            
                <div className="row p-3 pr-0 float-end">
                        <button className="btn btn-primary" type="submit" onClick={() => navigate(`/invention-edit/0/experiment/${id}`)}>
                            Create an invention based on this experiment
                        </button>
                </div>
            </div>
        </div>
    )
}

export default ExperimentView;