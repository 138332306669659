import { useEffect, useState } from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { Link, useOutletContext, useParams } from 'react-router-dom';
import * as DataUtil from '../../util/data';
// import Input from "../form/Input";
// import TextArea from "../form/TextArea";
// import * as DisplayUtil from '../../util/display';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MDEditor from '@uiw/react-md-editor';

const SolutionParadigmView = () => {

    // Get ID from the URL.
    let { id } = useParams();

    // Define constants. Edit this.
    const niceName = "Solution Paradigm";
    const editRoute = "solution-paradigm-edit";
    const getData = (j, i) => DataUtil.GetSolutionParadigm(j, i);
    const updateData = (j, d) => DataUtil.UpdateSolutionParadigm(j, d);

    // Define stateful variables.
    let { jwt } = useOutletContext();
    const [objData, setObjData] = useState({});
    const [editingNotes, setEditingNotes] = useState(false);
    const [editedNotes, setEditedNotes] = useState("");
    const [notesHeadingHovered, setNotesHeadingHovered] = useState(false);

    // // Save changes to any data.
    // const saveChanges = () => {
    //     updateData(jwt, objData);
    // }

    // Edit notes
    const editNotes = () => {
        setEditedNotes(objData.notes);
        setEditingNotes(true);
    }

    // Cancel editing notes
    const cancelEditingNotes = () => {
        setEditedNotes("");
        setEditingNotes(false);
    }

    // Save changes to notes
    const saveNotes = () => {
        setObjData({
            ...objData,
            notes: editedNotes
        })
        updateData(jwt, {...objData, notes: editedNotes});
        cancelEditingNotes();
    }

    // useEffect Hook
    useEffect(() => {

        if (!jwt || jwt === "") {
            return;
        }

        if (id < 1) {
            return;
        }

        getData(jwt, id).then(d => setObjData(d));
        
    }, [id, jwt])

    // Return for no data
    if (!jwt || !objData) {
        return <>Nothing here!</>
    }

    // *****************************
    // Main return
    return (
        <div className="row">
            <div className="col-md-12 pb-3" style={{height: "90vh", overflow: "scroll"}}>

                {/* Heading */}
                <div className="float-end mt-3">
                    <Link to={`/${editRoute}/${objData.id}`} className="link-secondary">
                        <FontAwesomeIcon icon="fa-solid fa-pen-to-square" size="2x" />
                    </Link>
                </div>
                <h1 className="display-5">
                    <strong>{objData?.name}</strong>
                </h1>

                {/* Description */}
                <p className="lead">{objData?.description}</p>
                <hr />
                
                {/* Notes */}
                <h4 style={{minHeight: "36px"}}
                    onMouseEnter={() => setNotesHeadingHovered(true)} 
                    onMouseLeave={() => setNotesHeadingHovered(false)}>
                    Notes&nbsp;
                    {notesHeadingHovered && ! editingNotes &&
                        <button type="button" className="btn btn-warning" style={{padding: "0px 5px"}}
                            onClick={() => editNotes()}>
                            <FontAwesomeIcon icon="fa-solid fa-pen" size="xs" />
                        </button>
                    }
                </h4>
                {!editingNotes &&
                    <div className="card p-3 pb-0" onClick={(evt) => {if (evt.detail > 2) {editNotes()}}}>
                        {objData?.notes !== "" &&
                        <ReactMarkdown children={objData?.notes}/>
                        }
                        {objData?.notes === "" &&
                        <div className="pb-3" style={{color: "#999", fontStyle: "italic"}}>No notes on this {niceName} yet.</div>
                        }
                    </div>
                }
                {editingNotes &&
                <>
                    <MDEditor
                        value={editedNotes}
                        onChange={(newVal) => { setEditedNotes(newVal) }}
                        preview="edit"
                    />
                    <div className="float-end mt-3">
                        <button type="button" className="btn btn-secondary justify-content-end"
                            onClick={() => cancelEditingNotes()} style={{marginLeft: "10px"}}>
                            <FontAwesomeIcon icon="fa-solid fa-xmark" />
                        </button>
                        <button type="button" className="btn btn-primary justify-content-end" 
                            onClick={() => saveNotes()} style={{marginLeft: "10px"}}>
                            <FontAwesomeIcon icon="fa-solid fa-check" />
                        </button>
                    </div>
                </>
                }
            </div>
        </div>
    )
}

export default SolutionParadigmView;