import MDEditor from '@uiw/react-md-editor';

const MarkdownEditor = (props) => {

    let label = "";
    if (!props.hideLabel)
    label = (
        <label htmlFor={props.name} className="form-label">
            {props.title}
        </label>
    )

    return (
        <div className="mb-3">
            {label}
            <MDEditor
                preview="edit"
                id={props.name}
                name={props.name}
                value={props.value}
                onChange={props.onChange}
            />
            <div className={props.errorDiv}>
                {props.errorMsg}
            </div>
        </div>
    );
}

export default MarkdownEditor;