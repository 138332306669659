// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import microscope from './../../images/microscope.svg'
import atom from './../../images/atom.svg'
import testTubes from './../../images/test-tubes.svg'
import computer from './../../images/computer.svg'
import { Link } from 'react-router-dom'

const Home = () => {

    return (
        <>
            <div className="text-center">

                <h2 style={{marginBottom: "0", marginLeft: "12px"}}>Welcome to</h2>
                <h1 style={{fontSize: "80px", marginTop: "-24px"}}>
                    <img src={atom} alt="atom" height="120px" style={{marginTop: "-18px"}}/>
                    {/* <FontAwesomeIcon icon="fa-solid fa-gears" /> */}
                    theLab
                </h1>
                <hr />

                <div className="row">
                    <div className="col-md-4">
                        <Link to="/research" style={{textDecoration: "none"}}>
                            <div className="card" style={{padding: "20px"}}>
                                <img src={microscope} alt="microscope" height="160px" />
                                <h1>Research</h1>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-4">
                        <Link to="/experiment" style={{textDecoration: "none"}}>
                            <div className="card" style={{padding: "20px"}}>
                                <img src={testTubes} alt="test tubes" height="160px" />
                                <h1>Experiment</h1>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-4">
                        <Link to="/invent" style={{textDecoration: "none"}}>
                            <div className="card" style={{padding: "20px"}}>
                                <img src={computer} alt="computer" height="160px" />
                                <h1>Invent</h1>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home;