import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext, useParams } from "react-router-dom";
import Input from "../form/Input";
import Swal from "sweetalert2";
import * as DataUtil from '../../util/data';
import * as DisplayUtil from '../../util/display';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MarkdownEditor from "../form/MarkdownEditor";
import Select from "../form/Select";

const InventionEdit = () => {

    // Get ID from the URL and define base data stateful variable.
    const { jwt } = useOutletContext();
    let {id, e_id} = useParams();
    const emptyData = useCallback(() => ({ // Edit this.
        id: 0,
        name: "",
        notes: "",
        experiment_id: e_id || "",
    }), [e_id]);
    const [objData, setObjData] = useState(emptyData());
    const [experimentOptions, setExperimentOptions] = useState([]);

    // Define constants. Edit this.
    const returnRoute = "invent"
    const viewRoute = "invention";
    const niceName = "Invention";
    const getData = (j, i) => DataUtil.GetInvention(j, i)
    const updateData = (j, d) => DataUtil.UpdateInvention(j, d)
    const insertData = (j, d) => DataUtil.InsertInvention(j, d)
    const deleteData = (j, i, s, e) => DataUtil.DeleteInvention(j, i, s, e);
    const getRelatedData = useCallback(() => {

        DataUtil.GetAllExperiments(jwt)
            .then((data) => {
                let options = data.map(d => {
                    return { id: d.id, value: d.summary };
                });
                setExperimentOptions(options);
            })
        
    }, [jwt]);

    // Define other stateful variables
    const navigate = useNavigate();

    const [errors, setErrors] = useState([]);

    const hasError = (key) => {
        return errors.indexOf(key) !== -1;
    }

    // useEffect Hook
    useEffect(() => {

        if (!jwt || jwt === "") {
            return;
        }

        getRelatedData();

        if (id === "0") {

            // We're adding new data.
            setObjData(d => (emptyData()));

        } else {

            // We're editing existing data.
            getData(jwt, id)
                .then(data => setObjData(data));
        }

    }, [jwt, navigate, id, getRelatedData, emptyData]);

    const alertError = () => {
        Swal.fire({
            title: 'Uh oh!',
            text: "There was an error processing the request :(",
            icon: 'error',
            confirmButtonText: 'Oh, ok',
        })
    }

    // Submit changes
    const handleSubmit = (evt) => {

        evt.preventDefault();

        // Handle validation.
        let errors = [];
        let requiredFields = [ // Edit this.
            { field: objData.name, name: "name"},
            { field: objData.experiment_id, name: "experiment"},
        ];

        requiredFields.forEach(function (obj) {
            if (obj.field === "") {
                errors.push(obj.name);
            }
        });
        setErrors(errors);
        console.log(errors);

        if (errors.length > 0) {
            return false;
        }

        // Passed validation, so save changes.

        // Convert any values which aren't meant to be strings
        let payload = {
            ...objData,
            experiment_id: parseInt(objData.experiment_id),
        }

        if (objData.id === 0) {
            console.log("SENDING DATA", payload);
            insertData(jwt, payload)
                .then((d) => {
                    if (d.error) {
                        console.log(d.error);
                        alertError();
                    } else {
                        Swal.fire({
                            title: 'Added',
                            text: `New ${niceName} Added!`,
                            icon: 'success',
                            confirmButtonText: 'Great!',
                        }).then(() => navigate(`/${viewRoute}/${objData.id}`));
                    }
                })
                .catch((err) => {
                    console.log(err);
                    alertError();
                })
        } else {
            updateData(jwt, payload)
                .then((d) => {
                    if (d.error) {
                        console.log(d.error);
                        alertError();
                    } else {
                        navigate(`/${viewRoute}/${objData.id}`);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    alertError();
                })
        }
    }

    // Form data change handlers
    const handleChange = () => (evt) => {
        let value = evt.target.value;
        let name = evt.target.name;
        setObjData({
            ...objData,
            [name]: value
        })
    }
    const handleChangeMarkdown = (name, val) => {
        setObjData({
            ...objData,
            [name]: val
        })
    }

    // Delete confirmation
    const confirmDelete = () => {
        DisplayUtil.DisplayDeleteConfirmation(
            jwt, 
            id, 
            niceName, 
            deleteData, 
            () => {
                navigate(`/${returnRoute}`);
            }
        );
    }

    // Main return
    return (
        <div className="">
            {id > 0 &&
                <div className="float-end">
                    <a href="#!" className="btn btn-danger ms-2" onClick={confirmDelete}>
                        <FontAwesomeIcon icon="fa-solid fa-trash" size="1x" />
                    </a>
                </div>
            }
            {id <= 0 &&
                <h2 className="display-6">
                    Add an {niceName}
                </h2>
            }
            {id > 0 &&
                <h2 className="display-6">
                    Edit {niceName}
                </h2>
            }
            <hr />
            {/* <pre>{JSON.stringify(objData, null, 3)}</pre> */}

            <form onSubmit={handleSubmit}>

                <input type="hidden" id="id" name="id" value={id}></input>

                {/* Actual form data.  Edit this. */}

                <Input 
                    title={"Name"}
                    className={"form-control"}
                    type={"text"}
                    name={"name"}
                    value={objData?.name}
                    onChange={handleChange("name")}
                    errorDiv={hasError("name") ? "text-danger" : "d-none"}
                    errorMsg={"Please enter a name"}
                    maxLength={255}
                />

                <Select 
                    title={"Experiment"}
                    name={"experiment"}
                    value={objData.experiment_id}
                    placeholder={"-- Select an Experiment --"}
                    onChange={(evt) => setObjData({...objData, experiment_id: evt.target.value})}
                    options={experimentOptions}
                    errorDiv={hasError("experiment") ? "text-danger" : "d-none"}
                    errorMsg={"Please select an experiment"}
                />

                <MarkdownEditor 
                    title={"Notes"}
                    className={"form-control"}
                    name={"notes"}
                    rows={5}
                    value={objData?.notes}
                    onChange={(val) => handleChangeMarkdown("notes", val)}
                    errorDiv={hasError("notes") ? "text-danger" : "d-none"}
                    errorMsg={"Please enter some notes"}
                />

                <hr />
                <div className="float-end">
                    {parseInt(id) === 0 &&
                        <Link to={`/${returnRoute}`} className="btn btn-secondary justify-content-end"
                            style={{marginRight: "10px"}}>
                            Cancel
                        </Link>
                    }
                    {parseInt(id) > 0 &&
                        <Link to={`/${viewRoute}/${objData?.id}`} className="btn btn-secondary justify-content-end"
                            style={{marginRight: "10px"}}>
                            Cancel
                        </Link>
                    }
                    <button className="btn btn-primary" type="submit">
                        {objData?.id !== 0 &&
                        <span>Save</span>
                        }
                        {objData?.id === 0 &&
                        <span>Save</span>
                        }
                    </button>
                </div>

            </form>
            <br/>
            
        </div>
    )
}

export default InventionEdit;