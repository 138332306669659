import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext, useParams } from "react-router-dom";
import Input from "../form/Input";
import Swal from "sweetalert2";
import * as DataUtil from '../../util/data';
import * as DisplayUtil from '../../util/display';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MarkdownEditor from "../form/MarkdownEditor";
import Select from "../form/Select";

const ExperimentEdit = () => {

    // Get ID from the URL and define base data stateful variable.
    const { jwt } = useOutletContext();
    let {id, pp_id} = useParams();
    const emptyData = useCallback(() => ({ // Edit this.
        id: 0,
        summary: "",
        notes: "",
        pain_point_id: pp_id || "",
        solution_paradigm_id: "",
        rank_control: "",
        rank_entry: "",
        rank_need: "",
        rank_time: "",
        rank_scale: "",
    }), [pp_id]);
    const [objData, setObjData] = useState(emptyData());
    const [painPointOptions, setPainPointOptions] = useState([]);
    const [paradigmOptions, setParadigmOptions] = useState([]);
    const [researchAreaOptions, setResearchAreaOptions] = useState([]);
    const [researchArea, setResearchArea] = useState("");

    // Define constants. Edit this.
    const returnRoute = "experiment"
    const viewRoute = "experiment";
    const niceName = "Experiment";
    const getData = (j, i) => DataUtil.GetExperiment(j, i)
    const updateData = (j, d) => DataUtil.UpdateExperiment(j, d)
    const insertData = (j, d) => DataUtil.InsertExperiment(j, d)
    const deleteData = (j, i, s, e) => DataUtil.DeleteExperiment(j, i, s, e);
    const rankings = [
        {id: 1, value: "1"},
        {id: 2, value: "2"},
        {id: 3, value: "3"},
        {id: 4, value: "4"},
        {id: 5, value: "5"},
    ];
    const getRelatedData = useCallback(() => {

        let researchAreas;
        DataUtil.GetAllResearchAreas(jwt)
            .then((data) => {
                researchAreas = data
                let options = data.map(d => {
                    return { id: d.id, value: d.name };
                });
                setResearchAreaOptions(options);
            })
            .then(() => {
                DataUtil.GetAllPainPoints(jwt)
                    .then((data) => {
                        let options = data.map(d => { 
                                let ra = researchAreas.find(r => r.id === d.research_area_id);
                                let valText = `[${ra.name}] ${d.summary}`;
                                return { id: d.id, value: valText }; 
                            });
                        setPainPointOptions(
                            options
                        );
                    });
            });

        DataUtil.GetAllSolutionParadigms(jwt)
            .then((data) => {
                let options = data.map(d => {
                    return { id: d.id, value: d.name };
                });
                setParadigmOptions(options);
            })
        
    }, [jwt]);
    const getPainPointOptions = (raID) => {
        if (raID === "") {
            let researchAreas;
            DataUtil.GetAllResearchAreas(jwt)
                .then((data) => {
                    researchAreas = data
                    DataUtil.GetAllPainPoints(jwt)
                        .then((data) => {
                            let options = data.map(d => { 
                                    let ra = researchAreas.find(r => r.id === d.research_area_id);
                                    let valText = `[${ra.name}] ${d.summary}`;
                                    return { id: d.id, value: valText }; 
                                });
                            setPainPointOptions(
                                options
                            );
                        });
                });
        } else {
            DataUtil.GetAllPainPointsByResearchArea(jwt, raID)
                .then((data) => {
                    let options = data?.map(d => { 
                            return { id: d.id, value: d.summary }; 
                        });
                    setPainPointOptions(
                        options
                    );
                });
        }
    }

    // Define other stateful variables
    const navigate = useNavigate();

    const [errors, setErrors] = useState([]);

    const hasError = (key) => {
        return errors.indexOf(key) !== -1;
    }

    // useEffect Hook
    useEffect(() => {

        if (!jwt || jwt === "") {
            return;
        }

        getRelatedData();

        if (id === "0") {

            // We're adding new data.
            setObjData(d => (emptyData()));

        } else {

            // We're editing existing data.
            getData(jwt, id)
                .then(data => setObjData(data));
        }

    }, [jwt, navigate, id, getRelatedData, emptyData]);

    const alertError = () => {
        Swal.fire({
            title: 'Uh oh!',
            text: "There was an error processing the request :(",
            icon: 'error',
            confirmButtonText: 'Oh, ok',
        })
    }

    // Submit changes
    const handleSubmit = (evt) => {

        evt.preventDefault();

        // Handle validation.
        let errors = [];
        let requiredFields = [ // Edit this.
            { field: objData.summary, name: "summary"},
            { field: objData.pain_point_id, name: "pain point"},
            { field: objData.solution_paradigm_id, name: "solution paradigm"},
        ];

        requiredFields.forEach(function (obj) {
            if (obj.field === "") {
                errors.push(obj.name);
            }
        });
        setErrors(errors);
        console.log(errors);

        if (errors.length > 0) {
            return false;
        }

        // Passed validation, so save changes.

        // Convert any values which aren't meant to be strings
        let payload = {
            ...objData,
            pain_point_id: parseInt(objData.pain_point_id),
            solution_paradigm_id: parseInt(objData.solution_paradigm_id),
            rank_control: parseInt(objData.rank_control) || null,
            rank_entry: parseInt(objData.rank_entry) || null,
            rank_need: parseInt(objData.rank_need) || null,
            rank_scale: parseInt(objData.rank_scale) || null,
            rank_time: parseInt(objData.rank_time) || null,
        }

        if (objData.id === 0) {
            insertData(jwt, payload)
                .then((d) => {
                    if (d.error) {
                        console.log(d.error);
                        alertError();
                    } else {
                        Swal.fire({
                            title: 'Added',
                            text: `New ${niceName} Added!`,
                            icon: 'success',
                            confirmButtonText: 'Great!',
                        }).then(() => navigate(`/${viewRoute}/${objData.id}`));
                    }
                })
                .catch((err) => {
                    console.log(err);
                    alertError();
                })
        } else {
            updateData(jwt, payload)
                .then((d) => {
                    if (d.error) {
                        console.log(d.error);
                        alertError();
                    } else {
                        navigate(`/${viewRoute}/${objData.id}`);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    alertError();
                })
        }
    }

    // Form data change handlers
    const handleChange = () => (evt) => {
        let value = evt.target.value;
        let name = evt.target.name;
        setObjData({
            ...objData,
            [name]: value
        })
    }
    const handleChangeMarkdown = (name, val) => {
        setObjData({
            ...objData,
            [name]: val
        })
    }

    // Delete confirmation
    const confirmDelete = () => {
        DisplayUtil.DisplayDeleteConfirmation(
            jwt, 
            id, 
            niceName, 
            deleteData, 
            () => {
                navigate(`/${returnRoute}`);
            }
        );
    }

    // Main return
    return (
        <div className="">
            {id > 0 &&
                <div className="float-end">
                    <a href="#!" className="btn btn-danger ms-2" onClick={confirmDelete}>
                        <FontAwesomeIcon icon="fa-solid fa-trash" size="1x" />
                    </a>
                </div>
            }
            {id <= 0 &&
                <h2 className="display-6">
                    Add an {niceName}
                </h2>
            }
            {id > 0 &&
                <h2 className="display-6">
                    Edit {niceName}
                </h2>
            }
            <hr />
            {/* <pre>{JSON.stringify(objData, null, 3)}</pre> */}

            <form onSubmit={handleSubmit}>

                <input type="hidden" id="id" name="id" value={id}></input>

                {/* Actual form data.  Edit this. */}

                <Input 
                    title={"Summary"}
                    className={"form-control"}
                    type={"text"}
                    name={"summary"}
                    value={objData?.summary}
                    onChange={handleChange("summary")}
                    errorDiv={hasError("summary") ? "text-danger" : "d-none"}
                    errorMsg={"Please enter a summary"}
                    maxLength={512}
                />

                <div className="card p-3 pb-0 pt-2 mb-3">
                    <div className="row p-0">
                        <div className="col-md-4">
                            <Select 
                                title={"Research Area"}
                                name={"researchArea"}
                                value={researchArea}
                                placeholder={"-- Select a Research Area --"}
                                onChange={(evt) => {
                                    setResearchArea(evt.target.value);
                                    getPainPointOptions(evt.target.value);
                                }}
                                options={researchAreaOptions}
                                // errorDiv={hasError("painPoint") ? "text-danger" : "d-none"}
                                // errorMsg={"Please select a pain point"}
                            />
                        </div>
                        <div className="col-md-8">
                            <Select 
                                title={"Pain Point"}
                                name={"painPoint"}
                                value={objData.pain_point_id}
                                placeholder={"-- Select a Pain Point --"}
                                onChange={(evt) => setObjData({...objData, pain_point_id: evt.target.value})}
                                options={painPointOptions}
                                errorDiv={hasError("pain point") ? "text-danger" : "d-none"}
                                errorMsg={"Please select a pain point"}
                            />
                        </div>
                    </div>
                </div>

                <Select 
                    title={"Solution Paradigm"}
                    name={"solutionParadigm"}
                    value={objData.solution_paradigm_id}
                    placeholder={"-- Select a Solution Paradigm --"}
                    onChange={(evt) => setObjData({...objData, solution_paradigm_id: evt.target.value})}
                    options={paradigmOptions}
                    errorDiv={hasError("solution paradigm") ? "text-danger" : "d-none"}
                    errorMsg={"Please select a solution paradigm"}
                />

                <div className="card p-3 pb-0 pt-3 mb-3">
                    <div className="row p-0">
                        <h4 className="display-6" style={{fontSize: "18px"}}>Ranking for five CENTS</h4>
                        <div className="col-md-2">
                            <Select 
                                title={"Control"}
                                name={"rankControl"}
                                value={objData.rank_control}
                                placeholder={"-- Select --"}
                                onChange={(evt) => setObjData({...objData, rank_control: evt.target.value})}
                                options={rankings}
                                errorDiv={hasError("rank_control") ? "text-danger" : "d-none"}
                                errorMsg={"Please select a ranking for Control"}
                            />
                        </div>
                        <div className="col-md-2">
                            <Select 
                                title={"Entry"}
                                name={"rankEntry"}
                                value={objData.rank_entry}
                                placeholder={"-- Select --"}
                                onChange={(evt) => setObjData({...objData, rank_entry: evt.target.value})}
                                options={rankings}
                                errorDiv={hasError("rank_entry") ? "text-danger" : "d-none"}
                                errorMsg={"Please select a ranking for Entry"}
                            />
                        </div>
                        <div className="col-md-2 text-center">
                            <Select 
                                title={"Need"}
                                name={"rankNeed"}
                                value={objData.rank_need}
                                placeholder={"-- Select --"}
                                onChange={(evt) => setObjData({...objData, rank_need: evt.target.value})}
                                options={rankings}
                                errorDiv={hasError("rank_need") ? "text-danger" : "d-none"}
                                errorMsg={"Please select a ranking for Need"}
                            />
                        </div>
                        <div className="col-md-2 text-center">
                            <Select 
                                title={"Time"}
                                name={"rankTime"}
                                value={objData.rank_time}
                                placeholder={"-- Select --"}
                                onChange={(evt) => setObjData({...objData, rank_time: evt.target.value})}
                                options={rankings}
                                errorDiv={hasError("rank_time") ? "text-danger" : "d-none"}
                                errorMsg={"Please select a ranking for Time"}
                            />
                        </div>
                        <div className="col-md-2 text-center">
                            <Select 
                                title={"Scale"}
                                name={"rankScale"}
                                value={objData.rank_scale}
                                placeholder={"-- Select --"}
                                onChange={(evt) => setObjData({...objData, rank_scale: evt.target.value})}
                                options={rankings}
                                errorDiv={hasError("rankScale") ? "text-danger" : "d-none"}
                                errorMsg={"Please select a ranking for Scale"}
                            />
                        </div>
                    </div>
                </div>

                <MarkdownEditor 
                    title={"Notes"}
                    className={"form-control"}
                    name={"notes"}
                    rows={5}
                    value={objData?.notes}
                    onChange={(val) => handleChangeMarkdown("notes", val)}
                    errorDiv={hasError("notes") ? "text-danger" : "d-none"}
                    errorMsg={"Please enter some notes"}
                />

                <hr />
                <div className="float-end">
                    {parseInt(id) === 0 &&
                        <Link to={`/${returnRoute}`} className="btn btn-secondary justify-content-end"
                            style={{marginRight: "10px"}}>
                            Cancel
                        </Link>
                    }
                    {parseInt(id) > 0 &&
                        <Link to={`/${viewRoute}/${objData?.id}`} className="btn btn-secondary justify-content-end"
                            style={{marginRight: "10px"}}>
                            Cancel
                        </Link>
                    }
                    <button className="btn btn-primary" type="submit">
                        {objData?.id !== 0 &&
                        <span>Save</span>
                        }
                        {objData?.id === 0 &&
                        <span>Save</span>
                        }
                    </button>
                </div>

            </form>
            <br/>
            
        </div>
    )
}

export default ExperimentEdit;