import { forwardRef } from "react";

const Input = forwardRef((props, ref) => {

    let label = "";
    if (!props.hideLabel)
    label = (
        <label htmlFor={props.name} className="form-label">
            {props.title}
        </label>
    )

    return (
        <div 
            className={props.containerClassName ? props.containerClassName : "mb-3"}
            style={props.containerStyle}>
            {label}
            <input 
                type={props.type}
                className={props.className}
                id={props.name}
                ref={ref}
                name={props.name}
                placeholder={props.placeholder}
                onChange={props.onChange}
                autoComplete={props.autoComplete}
                value={props.value}
                style={props.style}
                maxLength={props.maxLength}
            />
            <div className={props.errorDiv}>
                {props.errorMsg}
            </div>
        </div>
    );
})

export default Input;