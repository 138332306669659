import { forwardRef } from "react";

const SearchFilter = forwardRef((props, ref) => {

    return (
        <div 
            className={props.containerClassName ? props.containerClassName : "mb-3"}
            style={props.containerStyle}>
            <input 
                type={props.type}
                className={props.className}
                id={props.name}
                ref={ref}
                name={props.name}
                placeholder={props.placeholder}
                onChange={props.onChange}
                autoComplete={props.autoComplete}
                value={props.value}
                style={props.style}
                size={props.size}
            />
            <div className={props.errorDiv}>
                {props.errorMsg}
            </div>
        </div>
    );
})

export default SearchFilter;