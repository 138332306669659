import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState, useCallback } from 'react';
// import chemistry from './../../images/chemistry.svg'
import { Link, useOutletContext } from 'react-router-dom';
import * as DataUtil from './../../util/data';
import * as DisplayUtil from './../../util/display';
import computer from './../../images/computer.svg'

const Invent = () => {

    const { jwt } = useOutletContext();
    const [inventions, setInventions] = useState([]);
    const [activeTab, setActiveTab] = useState("e");

    // Switch to the inventions tab.
    const switchToInventionsTab = useCallback((filter = null) => {
        DataUtil.GetAllInventions(jwt).then(d => setInventions(d));
        setActiveTab("i");
    }, [jwt])

    // useEffect Hook
    useEffect(() => {

        if (jwt === "") {
            return;
        }

        switchToInventionsTab();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Handle deletion of an experiment
    const handleInventionDelete = (id) => {
        DisplayUtil.DisplayDeleteConfirmation(
            jwt, 
            id, 
            "Invention", 
            DataUtil.DeleteInvention, 
            () => switchToInventionsTab()
        );
    }

    //**************/
    // Return Content
    return (
        <div className="">

            {/* Main Heading */}
            <h1 className="display-4" >
                <img src={computer} alt="computer" height="42px" />
                <strong>Invent</strong>
            </h1>
            <hr />

            {/* Main Content */}
            <div className="card">

                {/* Tab Content */}
                <div className="card-body" style={{height: "72vh"}}>

                    {/* Inventions Tab */}
                    { activeTab === "i" &&
                        <>

                        {/* Main Table */}
                        <table className="table table-light table-hover">
                            <thead className="table-dark">
                                <tr>
                                    <th scope="col" colSpan={1}>Invention</th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {DisplayUtil.DisplayTableRows(
                                    inventions, 
                                    ["name"], 
                                    ["/invention"],
                                    [1],
                                    [120],
                                    "/invention-edit/", 
                                    handleInventionDelete,
                                )}
                            </tbody>
                        </table>

                        {/* Add Button */}
                        <div className="d-flex justify-content-end">
                            <Link to={`/invention-edit/0`} className="link-success">
                                <FontAwesomeIcon icon="fa-solid fa-square-plus" size="2x" />
                            </Link>
                        </div>

                        </>
                    }
                </div>
            </div>
            
        </div>
    )
}

export default Invent;