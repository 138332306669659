import { useEffect, useState } from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { Link, useOutletContext, useParams } from 'react-router-dom';
import dna from './../../images/dna.svg'
import * as DataUtil from '../../util/data';
import MarkdownEditor from "../form/MarkdownEditor";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import MDEditor from '@uiw/react-md-editor';

const InventionView = () => {

    let { jwt } = useOutletContext();
    let { id } = useParams();

    const [invention, setInvention] = useState({});
    const [experiment, setExperiment] = useState([]);
    const [paradigm, setParadigm] = useState([]);
    const [painPoint, setPainPoint] = useState([]);

    const [editingNotes, setEditingNotes] = useState(false);
    const [editedNotes, setEditedNotes] = useState("");
    const [notesHeadingHovered, setNotesHeadingHovered] = useState(false);

    // Edit notes
    const editNotes = () => {
        setEditedNotes(invention.notes);
        setEditingNotes(true);
    }

    // Cancel editing notes
    const cancelEditingNotes = () => {
        setEditedNotes("");
        setEditingNotes(false);
    }

    // Save changes to notes
    const saveInvention = () => {
        setInvention({
            ...invention,
            notes: editedNotes
        })
        DataUtil.UpdateInvention(jwt, {...invention, notes: editedNotes});
        cancelEditingNotes();
    }

    // useEffect Hook
    useEffect(() => {

        if (!jwt || jwt === "") {
            return;
        }

        if (id < 1) {
            return;
        }

        DataUtil.GetInvention(jwt, id).then(d => {
            setInvention(d);
            DataUtil.GetExperiment(jwt, d.experiment_id).then(e => {
                setExperiment(e);
                DataUtil.GetSolutionParadigm(jwt, e.solution_paradigm_id).then(d => setParadigm(d));
                DataUtil.GetPainPoint(jwt, e.pain_point_id).then(d => setPainPoint(d));
            });
        });
        
    }, [id, jwt])

    // Return for no data
    if (!jwt || !invention) {
        return <>Nothing here!</>
    }

    // *****************************
    // Main return
    return (
        <div className="row">
            <div className="col-md-12 pb-3" style={{height: "90vh", overflow: "scroll"}}>

                {/* Heading */}
                <div className="float-end mt-3">
                    <Link to={`/experiment-edit/${id}`} className="link-secondary">
                        <FontAwesomeIcon icon="fa-solid fa-pen-to-square" size="2x" />
                    </Link>
                </div>
                <h4 className="display-6" style={{marginBottom: "-0px", fontSize: "32px"}}>
                    <img src={dna} alt="dna" height="28px" width="28px" style={{marginBottom: "10px"}} />
                    Invention:
                </h4>
                <h1 className="display-5" style={{lineHeight: "48px", fontSize: "48px"}}>
                    <strong>{invention?.name}</strong>
                </h1>

                <hr/>
                <div className="lead text-center">
                    <p style={{textTransform: "", fontSize: "24px", marginBottom: "12px"}}>
                        <Link 
                            to={`/experiment/${experiment.id}`}
                            className="link-dark" 
                            style={{fontWeight: "500", textDecoration: "none"}}>
                            {experiment.summary}
                        </Link><br/>
                    </p>
                    <span style={{textTransform: ""}}>
                        A <Link 
                            to={`/solution-paradigm/${paradigm.id}`}
                            className="link-info" 
                            style={{textTransform: "lowercase", fontWeight: "500", textDecoration: "none"}}>
                            {paradigm.name}
                        </Link> solution for the problem:<br/>
                    </span>
                    <Link 
                        to={`/research-area/${painPoint.research_area_id}`}
                        className="link-secondary" 
                        style={{fontWeight: "500", textDecoration: "none"}}>
                        {painPoint?.summary}
                    </Link>
                </div>
                <hr />
                
                {/* Notes */}
                <h3 className="display-6"  style={{fontSize: "28px"}}
                    onMouseEnter={() => setNotesHeadingHovered(true)} 
                    onMouseLeave={() => setNotesHeadingHovered(false)}>
                    Notes&nbsp;
                    {notesHeadingHovered && ! editingNotes &&
                        <button type="button" className="btn btn-warning" style={{padding: "0px 5px"}}
                            onClick={() => editNotes()}>
                            <FontAwesomeIcon icon="fa-solid fa-pen" size="xs" />
                        </button>
                    }
                </h3>
                {!editingNotes &&
                    <div className="card p-3 pb-0" onClick={(evt) => {if (evt.detail > 2) {editNotes();}}}>
                        {invention?.notes !== "" &&
                            <ReactMarkdown children={invention?.notes}/>
                        }
                        {invention?.notes === "" &&
                            <div className="pb-3" style={{color: "#999", fontStyle: "italic"}}>No notes on this invention yet.</div>
                        }
                    </div>
                }
                {editingNotes &&
                <>
                    <MarkdownEditor
                        title={"Notes"}
                        className={"form-control"}
                        name={"notes"}
                        value={editedNotes}
                        onChange={(newVal) => {
                            setEditedNotes(newVal)
                        }}
                        preview="edit"
                        hideLabel={true}
                    />
                    <div className="float-end mt-3">
                        <button type="button" className="btn btn-secondary justify-content-end"
                            onClick={() => cancelEditingNotes()} style={{marginLeft: "10px"}}>
                            <FontAwesomeIcon icon="fa-solid fa-xmark" />
                        </button>
                        <button type="button" className="btn btn-primary justify-content-end" 
                            onClick={() => saveInvention(invention)} style={{marginLeft: "10px"}}>
                            <FontAwesomeIcon icon="fa-solid fa-check" />
                        </button>
                    </div>
                </>
                }
            </div>
        </div>
    )
}

export default InventionView;