import { useState } from "react";
import Input from "../form/Input";
import { useNavigate, useOutletContext } from "react-router-dom";

const Login = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const { setJwt } = useOutletContext();
    const { setAlertMessage } = useOutletContext();
    const { setAlertClassName } = useOutletContext();
    const { toggleRefresh } = useOutletContext();

    const navigate = useNavigate();

    const handleSubmit = (evt) => {
        evt.preventDefault();
        
        // Build the request payload.
        let payload = {
            email: email,
            password: password,
        }

        // Build the request options.
        const requestOptions = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(payload),
        }

        // Send the authentication request, and set the JWT if successful.
        fetch(`${process.env.REACT_APP_BACKEND}/authenticate`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.error) {
                    setAlertClassName("alert-danger");
                    setAlertMessage(data.message);
                } else {
                    // console.log("ACCESS TOKEN: " + data.access_token) // TODO: Just to help with testing. DELETE.
                    setJwt(data.access_token);
                    setAlertClassName("d-none");
                    setAlertMessage("");
                    toggleRefresh(true);
                    navigate("/");
                }
            })
            .catch(error => {
                setAlertClassName("alert-danger");
                setAlertMessage(error.message);
            })
    }

    return (
        <div className="col-md-6 offset-md-3">
            <h1>
                <em><strong>Log In</strong></em>
            </h1>
            <hr />

            <form onSubmit={handleSubmit}>

                <Input 
                    title="Email Address"
                    type="email"
                    className="form-control"
                    name="email"
                    autoComplete="email-new"
                    onChange={(evt) => setEmail(evt.target.value)}
                />
                
                <Input 
                    title="Password"
                    type="password"
                    className="form-control"
                    name="password"
                    autoComplete="password-new"
                    onChange={(evt) => setPassword(evt.target.value)}
                />

                <hr />

                <input 
                    type="submit"
                    className="btn btn-primary"
                    value="Log In" 
                />
            </form>
        </div>
    )
}

export default Login;