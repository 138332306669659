
async function GetRequest(jwt, endpoint) {

    if (!jwt || jwt === "") {
        console.log("GET request attempted without JWT");
    }

    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + jwt);

    const requestOptions = {
        method: "GET",
        headers: headers,
        credentials: "include",
    };

    // console.log(`Making GET request to ${process.env.REACT_APP_BACKEND}/${endpoint}`);

    return fetch(`${process.env.REACT_APP_BACKEND}/${endpoint}`, requestOptions)
        .then((resp) => resp.json())
        .then((data) => { 
            if (data?.error) {
                console.log("Error sending API request: " + (data.message || "Unknown error."));
                return;
            }
            return data;
        })
        .catch(err => { console.log(err); });
}

async function PutRequest(jwt, endpoint, payload) {

    if (!jwt || jwt === "") {
        console.log("PUT request attempted without JWT");
    }

    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + jwt);

    const requestOptions = {
        method: "PUT",
        headers: headers,
        credentials: "include",
        body: JSON.stringify(payload),
    };

    console.log(`Making PUT request to ${process.env.REACT_APP_BACKEND}/${endpoint}`);
    console.log(payload);

    return fetch(`${process.env.REACT_APP_BACKEND}/${endpoint}`, requestOptions)
        .then((resp) => resp.json())
        .then((data) => { 
            if (data?.error) {
                console.log("Error sending API request: " + (data.message || "Unknown error."));
                return;
            }
            return data;
        })
        .catch(err => { console.log(err); });
}

async function PatchRequest(jwt, endpoint, payload) {

    if (!jwt || jwt === "") {
        console.log("PATCH request attempted without JWT");
    }

    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + jwt);

    const requestOptions = {
        method: "PATCH",
        headers: headers,
        credentials: "include",
        body: JSON.stringify(payload),
    };

    console.log(`Making PATCH request to ${process.env.REACT_APP_BACKEND}/${endpoint}`);
    console.log(payload);

    return fetch(`${process.env.REACT_APP_BACKEND}/${endpoint}`, requestOptions)
        .then((resp) => resp.json())
        .then((data) => { 
            if (data?.error) {
                console.log("Error sending API request: " + (data.message || "Unknown error."));
                return;
            }
            return data;
        })
        .catch(err => { console.log(err); });
}

async function DeleteRequest(jwt, endpoint, id, handleSuccess = null, handleError = null) {

    if (!jwt || jwt === "") {
        console.log("DELETE request attempted without JWT");
    }

    let headers = new Headers();
    headers.append("Authorization", "Bearer " + jwt);
    const requestOptions = {
        method: "DELETE",
        headers: headers,
        credentials: "include",
    }

    console.log(`Making DELETE request to ${process.env.REACT_APP_BACKEND}/${endpoint}/${id}`);

    fetch(`${process.env.REACT_APP_BACKEND}/${endpoint}/${id}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
            if (data.error) {
                if (handleError !== null) {
                    handleError(data);
                }
                console.log(data.error);
            } else {
                if (handleSuccess !== null) {
                    handleSuccess(data);
                }
            }
        })
        .catch(err => { console.log(err); });
}







// INSERT FUNCTIONS

export function InsertSolutionParadigm(jwt, newData) {
    return PutRequest(jwt, `api/solution-paradigms/0`, newData);
}

export function InsertResearchCategory(jwt, newData) {
    return PutRequest(jwt, `api/research-categories/0`, newData);
}

export function InsertInfoSource(jwt, newData) {
    return PutRequest(jwt, `api/info-sources/0`, newData);
}

export function InsertPainPoint(jwt, newData) {
    return PutRequest(jwt, `api/pain-points/0`, newData);
}

export function InsertExperiment(jwt, newData) {
    return PutRequest(jwt, `api/experiments/0`, newData);
}

export function InsertValueAttribute(jwt, newData) {
    return PutRequest(jwt, `api/value-attributes/0`, newData);
}

export function InsertTradeoff(jwt, newData) {
    return PutRequest(jwt, `api/tradeoffs/0`, newData);
}

export function InsertIdeaSource(jwt, newData) {
    return PutRequest(jwt, `api/idea-sources/0`, newData);
}

export function InsertInvention(jwt, newData) {
    return PutRequest(jwt, `api/inventions/0`, newData);
}



// UPDATE FUNCTIONS

export function UpdateResearchArea(jwt, newData) {
    return PatchRequest(jwt, `api/research-areas/${newData.id}`, newData);
}

export function UpdateResearchCategory(jwt, newData) {
    return PatchRequest(jwt, `api/research-categories/${newData.id}`, newData);
}

export function UpdateSolutionParadigm(jwt, newData) {
    return PatchRequest(jwt, `api/solution-paradigms/${newData.id}`, newData);
}

export function UpdateInfoSource(jwt, newData) {
    return PatchRequest(jwt, `api/info-sources/${newData.id}`, newData);
}

export function UpdatePainPoint(jwt, newData) {
    return PatchRequest(jwt, `api/pain-points/${newData.id}`, newData);
}

export function UpdateExperiment(jwt, newData) {
    return PatchRequest(jwt, `api/experiments/${newData.id}`, newData);
}

export function UpdateValueAttribute(jwt, newData) {
    return PatchRequest(jwt, `api/value-attributes/${newData.id}`, newData);
}

export function UpdateTradeoff(jwt, newData) {
    return PatchRequest(jwt, `api/tradeoffs/${newData.id}`, newData);
}

export function UpdateIdeaSource(jwt, newData) {
    return PatchRequest(jwt, `api/idea-sources/${newData.id}`, newData);
}

export function UpdateInvention(jwt, newData) {
    return PatchRequest(jwt, `api/inventions/${newData.id}`, newData);
}



// DELETE FUNCTIONS

export function DeleteResearchArea(jwt, id, handleSuccess = null, handleError = null) {
    return DeleteRequest(jwt, `api/research-areas`, id, handleSuccess, handleError);
}

export function DeleteResearchCategory(jwt, id, handleSuccess = null, handleError = null) {
    return DeleteRequest(jwt, `api/research-categories`, id, handleSuccess, handleError);
}

export function DeleteSolutionParadigm(jwt, id, handleSuccess = null, handleError = null) {
    return DeleteRequest(jwt, `api/solution-paradigms`, id, handleSuccess, handleError);
}

export function DeleteInfoSource(jwt, id, handleSuccess = null, handleError = null) {
    return DeleteRequest(jwt, `api/info-sources`, id, handleSuccess, handleError);
}

export function DeletePainPoint(jwt, id, handleSuccess = null, handleError = null) {
    return DeleteRequest(jwt, `api/pain-points`, id, handleSuccess, handleError);
}

export function DeleteExperiment(jwt, id, handleSuccess = null, handleError = null) {
    return DeleteRequest(jwt, `api/experiments`, id, handleSuccess, handleError);
}

export function DeleteValueAttribute(jwt, id, handleSuccess = null, handleError = null) {
    return DeleteRequest(jwt, `api/value-attributes`, id, handleSuccess, handleError);
}

export function DeleteTradeoff(jwt, id, handleSuccess = null, handleError = null) {
    return DeleteRequest(jwt, `api/tradeoffs`, id, handleSuccess, handleError);
}

export function DeleteIdeaSource(jwt, id, handleSuccess = null, handleError = null) {
    return DeleteRequest(jwt, `api/idea-sources`, id, handleSuccess, handleError);
}

export function DeleteInvention(jwt, id, handleSuccess = null, handleError = null) {
    return DeleteRequest(jwt, `api/inventions`, id, handleSuccess, handleError);
}



// RETRIEVE FUNCTIONS

export function GetResearchArea(jwt, id) {
    return GetRequest(jwt, `api/research-areas/${id}`);
}

export function GetResearchCategory(jwt, id) {
    return GetRequest(jwt, `api/research-category/${id}`);
}

export function GetSolutionParadigm(jwt, id) {
    return GetRequest(jwt, `api/solution-paradigms/${id}`);
}

export function GetExperiment(jwt, id) {
    return GetRequest(jwt, `api/experiments/${id}`);
}

export function GetPainPoint(jwt, id) {
    return GetRequest(jwt, `api/pain-points/${id}`);
}

export function GetIdeaSource(jwt, id) {
    return GetRequest(jwt, `api/idea-sources/${id}`);
}

export function GetInvention(jwt, id) {
    return GetRequest(jwt, `api/inventions/${id}`);
}



// GET ALL FUNCTIONS

export function GetAllResearchAreas(jwt) {
    return GetRequest(jwt, `api/research-areas`);
}

export function GetAllResearchCategories(jwt) {
    return GetRequest(jwt, `api/research-categories`);
}

export function GetAllResearchAreasByCategory(jwt, id) {
    return GetRequest(jwt, `api/research-areas/category/${id}`);
}

export function GetAllInfoSources(jwt) {
    return GetRequest(jwt, `api/info-sources`);
}

export function GetAllInfoSourcesByResearchArea(jwt, id) {
    return GetRequest(jwt, `api/info-sources/research-area/${id}`);
}

export function GetAllSolutionParadigms(jwt) {
    return GetRequest(jwt, `api/solution-paradigms`);
}

export function GetAllPainPoints(jwt) {
    return GetRequest(jwt, `api/pain-points`);
}

export function GetAllPainPointsByResearchArea(jwt, id) {
    return GetRequest(jwt, `api/pain-points/research-area/${id}`);
}

export function GetAllExperiments(jwt) {
    return GetRequest(jwt, `api/experiments`);
}

export function GetAllExperimentsByPainPoint(jwt, id) {
    return GetRequest(jwt, `api/experiments/pain-point/${id}`);
}

export function GetAllExperimentsBySolutionParadigm(jwt, id) {
    return GetRequest(jwt, `api/experiments/solution-paradigm/${id}`);
}

export function GetAllValueAttributes(jwt) {
    return GetRequest(jwt, `api/value-attributes`);
}

export function GetAllValueAttributesByExperiment(jwt, id) {
    return GetRequest(jwt, `api/value-attributes/experiment/${id}`);
}

export function GetAllTradeoffs(jwt) {
    return GetRequest(jwt, `api/tradeoffs`);
}

export function GetAllTradeoffsByExperiment(jwt, id) {
    return GetRequest(jwt, `api/tradeoffs/experiment/${id}`);
}

export function GetAllInventions(jwt) {
    return GetRequest(jwt, `api/inventions`);
}

export function GetAllInventionsByExperiment(jwt, id) {
    return GetRequest(jwt, `api/inventions/experiment/${id}`);
}

export function GetAllIdeaSources(jwt) {
    return GetRequest(jwt, `api/idea-sources`);
}