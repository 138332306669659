import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState, useCallback } from 'react';
// import chemistry from './../../images/chemistry.svg'
import { Link, useOutletContext } from 'react-router-dom';
import * as DataUtil from './../../util/data';
import * as DisplayUtil from './../../util/display';
import Filter from './../form/Filter';
import SearchFilter from '../form/SearchFilter';
import testTubes from './../../images/test-tubes.svg'

const Experiment = () => {

    const { jwt } = useOutletContext();
    const [, setExperiments] = useState([]);
    const [pFilteredExperiments, setPFilteredExperiments] = useState([]);
    const [filteredExperiments, setFilteredExperiments] = useState([]);
    const [paradigms, setParadigms] = useState([]);
    const [painPoints, setPainPoints] = useState([]);
    const [paradigmOptions, setParadigmOptions] = useState([]);
    const [currPFilter, setCurrPFilter] = useState(-1);
    const [currSearchFilter, setCurrSearchFilter] = useState("");
    const [activeTab, setActiveTab] = useState("e");

    // Update the search filter.
    const filterByText = useCallback((val, pFiltered = null) => {
        let newVal = val || "";
        if (pFiltered == null) {
            pFiltered = pFilteredExperiments
        }
        setCurrSearchFilter(newVal);
        if (newVal !== "") {
            let filtered = pFiltered.filter(e => {
                return e.summary.toLowerCase().includes(newVal);
            })
            setFilteredExperiments(filtered);
        } else {
            setFilteredExperiments(pFiltered);
        }
    }, [pFilteredExperiments]);

    const getExperimentsWithExtraData = useCallback((data, pps, sps) => {
        let resp = data.map(e => {
            return {
                ...e,
                pain_point: pps.find((p) => p.id === e.pain_point_id)?.summary,
                research_area_id: pps.find((p) => p.id === e.pain_point_id)?.research_area_id,
                solution_paradigm: sps.find((p) => p.id === e.solution_paradigm_id)?.name
            }
        });
        return resp;
    }, []);

    // Update the paradigm filter.
    const filterByParadigm = useCallback((val, pps, sps) => {
        let newVal = val || -1;
        setCurrPFilter(newVal);
        if (newVal >= 0) {
            DataUtil.GetAllExperimentsBySolutionParadigm(jwt, newVal).then((es) => {
                if (!es) {
                    es = [];
                }
                let data = getExperimentsWithExtraData(es, pps, sps);
                setPFilteredExperiments(data);
                filterByText(currSearchFilter, data);
            });
        } else {
            DataUtil.GetAllExperiments(jwt).then((es) => {
                let data = getExperimentsWithExtraData(es, pps, sps);
                setPFilteredExperiments(data);
                filterByText(currSearchFilter, data);
            });
        }
    }, [jwt, currSearchFilter, filterByText, getExperimentsWithExtraData]);

    // Switch to the experiments tab.
    const switchToExperimentsTab = useCallback((filter = null) => {
        DataUtil.GetAllSolutionParadigms(jwt)
            .then((sps) => { 
                let pars = [];
                sps.forEach(par => {
                    pars.push({
                        id: par.id,
                        value: par.name
                    })
                });
                setParadigmOptions(pars);
                setParadigms(sps);
                DataUtil.GetAllPainPoints(jwt)
                    .then((pps) => {
                        setPainPoints(pps)
                        DataUtil.GetAllExperiments(jwt)
                            .then((es) => { 
                                let data = getExperimentsWithExtraData(es, pps, sps)
                                setExperiments(data);
                                filterByParadigm(filter, pps, sps);
                            });
                    });
                });

        setActiveTab("e");
    }, [jwt, getExperimentsWithExtraData, filterByParadigm])

    // useEffect Hook
    useEffect(() => {

        if (jwt === "") {
            return;
        }

        switchToExperimentsTab();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Handle deletion of an experiment
    const handleExperimentDelete = (id) => {
        DisplayUtil.DisplayDeleteConfirmation(
            jwt, 
            id, 
            "Experiment", 
            DataUtil.DeleteExperiment, 
            () => switchToExperimentsTab(currPFilter)
        );
    }

    //**************/
    // Return Content
    return (
        <div className="">

            {/* Main Heading */}
            <h1 className="display-4" >
                <img src={testTubes} alt="test tubes" height="42px" />
                <strong>Experiment</strong>
            </h1>
            <hr />

            {/* Main Content */}
            <div className="card">

                {/* Header with Tabs */}
                {/* <ul className="nav nav-tabs card-header pb-0">
                    <li className="nav-item">
                        <button className={`nav-link link-secondary ${activeTab === "e" ? "active" : ""}`} href="#" onClick={switchToExperimentsTab}>
                            <img src={chemistry} alt="chemistry" height="24px" />&nbsp;Experiments
                        </button>
                    </li>
                </ul> */}

                {/* Tab Content */}
                <div className="card-body" style={{height: "72vh"}}>

                    {/* Experiments Tab */}
                    { activeTab === "e" &&
                        <>
                        
                        <div className="d-flex flex-row justify-content-end">

                            {/* Search Filter */}
                            <div className="d-flex justify-content-end">
                                <SearchFilter
                                    className={"form-control"} 
                                    name={"search-filter"}
                                    value={currSearchFilter}
                                    onChange={(evt) => filterByText(evt.target.value)}
                                    placeholder={"Filter by summary..."}
                                    size={25}
                                />
                            </div>

                            {/* Paradigm Filter */}
                            <div className="d-flex justify-content-end">
                                <Filter 
                                    containerStyle={{marginLeft: "10px"}}
                                    name={"categories"}
                                    value={currPFilter}
                                    onChange={(evt) => filterByParadigm(evt.target.value, painPoints, paradigms)}
                                    placeholder={"Filter by paradigm..."}
                                    options={paradigmOptions}
                                />
                            </div>
                        </div>

                        {/* Main Table */}
                        <table className="table table-light table-hover">
                            <thead className="table-dark">
                                <tr>
                                    <th scope="col" colSpan={2}>Experiment</th>
                                    <th scope="col" colSpan={3}>Pain Point</th>
                                    <th scope="col" colSpan={2}>Solution Paradigm</th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {DisplayUtil.DisplayTableRows(
                                    filteredExperiments, 
                                    ["summary", "pain_point", "solution_paradigm"], 
                                    [   {id: "id", value: "/experiment"}, 
                                        {id: "research_area_id", value: "/research-area"}, 
                                        {id: "solution_paradigm_id", value: "/solution-paradigm"}],
                                    [2, 3, 2],
                                    [40, 80, 40],
                                    "/experiment-edit/", 
                                    handleExperimentDelete,
                                )}
                            </tbody>
                        </table>

                        {/* Add Button */}
                        <div className="d-flex justify-content-end">
                            <Link to={`/experiment-edit/0`} className="link-success">
                                <FontAwesomeIcon icon="fa-solid fa-square-plus" size="2x" />
                            </Link>
                        </div>

                        </>
                    }
                </div>
            </div>
            
        </div>
    )
}

export default Experiment;