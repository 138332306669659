import { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext, useParams } from "react-router-dom";
import Input from "../form/Input";
import Swal from "sweetalert2";
import * as DataUtil from '../../util/data';
import * as DisplayUtil from '../../util/display';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ResearchCategoryEdit = () => {

    // Get ID from the URL and define base data stateful variable.
    let {id} = useParams();
    const emptyData = () => ({ // Edit this.
        id: 0,
        name: ""
    })
    const [objData, setObjData] = useState(emptyData());

    // Define constants. Edit this.
    const returnRoute = "research"
    const niceName = "Research Category";
    const getData = (j, i) => DataUtil.GetResearchCategory(j, i)
    const updateData = (j, d) => DataUtil.UpdateResearchCategory(j, d)
    const insertData = (j, d) => DataUtil.InsertResearchCategory(j, d)
    const deleteData = (j, i, s, e) => DataUtil.DeleteResearchCategory(j, i, s, e);

    // Define other stateful variables
    const navigate = useNavigate();
    const { jwt } = useOutletContext();

    const [errors, setErrors] = useState([]);

    const hasError = (key) => {
        return errors.indexOf(key) !== -1;
    }

    const loadEmpty = () => {
        setObjData(emptyData());
    }

    // useEffect Hook
    useEffect(() => {

        if (!jwt || jwt === "") {
            return;
        }

        if (id === "0") {

            // We're adding new data.
            setObjData(d => (emptyData()));

        } else {

            // We're editing existing data.
            getData(jwt, id)
                .then(data => setObjData(data));
        }

    }, [jwt, navigate, id]);

    const alertError = () => {
        Swal.fire({
            title: 'Uh oh!',
            text: "There was an error processing the request :(",
            icon: 'error',
            confirmButtonText: 'Oh, ok',
        })
    }

    // Submit changes
    const handleSubmit = (evt) => {

        evt.preventDefault();

        // Handle validation.
        let errors = [];
        let requiredFields = [ // Edit this.
            { field: objData.name, name: "name"},
        ];

        requiredFields.forEach(function (obj) {
            if (obj.field === "") {
                errors.push(obj.name);
            }
        });
        setErrors(errors);

        if (errors.length > 0) {
            return false;
        }

        // Passed validation, so save changes.
        if (objData.id === 0) {
            insertData(jwt, objData)
                .then((d) => {
                    if (d.error) {
                        console.log(d.error);
                        alertError();
                    } else {
                        Swal.fire({
                            title: 'Added',
                            text: `New ${niceName} Added!`,
                            icon: 'success',
                            confirmButtonText: 'Great!',
                        }).then(() => loadEmpty());
                    }
                })
                .catch((err) => {
                    console.log(err);
                    alertError();
                })
        } else {
            updateData(jwt, objData)
                .then((d) => {
                    if (d.error) {
                        console.log(d.error);
                        alertError();
                    } else {
                        navigate(`/${returnRoute}`);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    alertError();
                })
        }
    }

    // Form data change handlers
    const handleChange = () => (evt) => {
        let value = evt.target.value;
        let name = evt.target.name;
        setObjData({
            ...objData,
            [name]: value
        })
    }

    // Delete confirmation
    const confirmDelete = () => {
        DisplayUtil.DisplayDeleteConfirmation(
            jwt, 
            id, 
            niceName, 
            deleteData, 
            () => {
                navigate(`/${returnRoute}`);
            }
        );
    }

    // Main return
    return (
        <div className="">
            {id > 0 &&
                <div className="float-end">
                    <a href="#!" className="btn btn-danger ms-2" onClick={confirmDelete}>
                        <FontAwesomeIcon icon="fa-solid fa-trash" size="1x" />
                    </a>
                </div>
            }
            {id <= 0 &&
                <h2 className="display-6">
                    Add a {niceName}
                </h2>
            }
            {id > 0 &&
                <h2 className="display-6">
                    Edit {niceName}
                </h2>
            }
            <hr />
            {/* <pre>{JSON.stringify(data, null, 3)}</pre> */}

            <form onSubmit={handleSubmit}>

                <input type="hidden" id="id" name="id" value={id}></input>

                {/* Actual form data.  Edit this. */}

                <Input 
                    title={"Name"}
                    className={"form-control"}
                    type={"text"}
                    name={"name"}
                    value={objData?.name}
                    onChange={handleChange("name")}
                    errorDiv={hasError("name") ? "text-danger" : "d-none"}
                    errorMsg={"Please enter a name"}
                    maxLength={255}
                />

                <hr />
                <div className="float-end">
                    {parseInt(id) === 0 &&
                        <Link to={`/${returnRoute}`} className="btn btn-secondary justify-content-end"
                            style={{marginRight: "10px"}}>
                            Cancel
                        </Link>
                    }
                    {parseInt(id) > 0 &&
                        <Link to={`/${returnRoute}`} className="btn btn-secondary justify-content-end"
                            style={{marginRight: "10px"}}>
                            Cancel
                        </Link>
                    }
                    <button className="btn btn-primary" type="submit">
                        {objData?.id !== 0 &&
                        <span>Save</span>
                        }
                        {objData?.id === 0 &&
                        <span>Save & Add Another</span>
                        }
                    </button>
                </div>

            </form>
            <br/>
            
        </div>
    )
}

export default ResearchCategoryEdit;